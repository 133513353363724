import React, { createContext, useState, useContext } from "react";

export const AccordionContext = createContext();

export const AccordionProvider = ({ children }) => {
  const [activeAccordion, setActiveAccordion] = useState(0);
  const incrementAccordion = () => {
    // check if you're at last accordion. If so, loop. Else, Increment
    setActiveAccordion((current) =>
      current === children?.length ? 0 : current + 1
    );
  };

  const styles = {
    accordion: { borderRadius: "10px" },
    accordionHeader: {
      backgroundColor: "#003396",
      border: "1px solid black",
    },
    accordionHeaderText: { fontSize: 24, fontWeight: "Bold", color: "white" },
  };

  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, { accordionid: index + 1 })
  );

  return (
    <AccordionContext.Provider
      value={{
        activeAccordion,
        setActiveAccordion,
        incrementAccordion,
        styles,
      }}
    >
      {childrenWithProps}
    </AccordionContext.Provider>
  );
};

export const useAccordion = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error("useAccordion must be used within a AccordionProvider");
  }
  return context;
};
