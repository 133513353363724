// Implementations provided by 'https://stackoverflow.com/a/19101235'

// flattens prefilled answer document to fit correct formatting of prefilling default values
function flattenObject(data) {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + "." + i);
      if (l === 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
}

function unflattenObject(data) {
  "use strict";
  if (Object(data) !== data || Array.isArray(data)) return data;
  var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = "",
      m;
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[""] || resultholder;
}

function getNestedValue(obj, path) {
  if (obj === undefined) return undefined;
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

function destroySession() {
  // We use fetch() with keepalive:true because it reliably sends a POST request, even when the browser/page closes
  fetch(`${process.env.REACT_APP_BACKEND_URL}/patient/destroy-session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    keepalive: true,
    credentials: "include",
  });
}

module.exports = {
  flattenObject: flattenObject,
  unflattenObject: unflattenObject,
  getNestedValue: getNestedValue,
  destroySession: destroySession,
};
