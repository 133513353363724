const termsAndConditionsText = `
<br /><strong>PLEASE READ CAREFULLY BEFORE SIGNING</strong><br />
<br />
This form is designed to comply with the Health Insurance Portability and Accountability Act of 1996 (HIPAA) to give your consent for the release, use, and disclosure of your health information that is stored in the Electronic Medical Records (EMR) system of AttumOrtho.
<br />
<br /><strong>AUTHORIZATION:</strong><br />
By signing this form, you authorize AttumOrtho to use and disclose your individually identifiable health information as described below.<br />

<br /><strong>PARTIES TO WHOM AttumOrtho MAY DISCLOSE HEALTH INFORMATION:</strong><br />
Your health information may be disclosed to any person, agency, or organization as you have specified in written or oral form, including but not limited to:<br />
<br />
Healthcare providers<br />
Insurance companies<br />
Government agencies<br />
Legal authorities<br />

<br /><strong>PURPOSE:</strong><br />
Your health information may be used or disclosed for the following purposes:<br />
<br />
Treatment<br />
Payment<br />
Healthcare operations<br />
Legal or regulatory proceedings<br />
Other purposes as allowed or required by law<br />

<br /><strong>EXPIRATION:</strong><br />
This authorization will remain in effect until revoked by you in writing. You may revoke this authorization at any time by providing written notification to AttumOrtho. Revocation will not affect any actions AttumOrtho has already taken in reliance on this authorization prior to the receipt of your written revocation.<br />

<br /><strong>YOUR RIGHTS:</strong><br />
You understand that you have the right to:<br />
<br />
Revoke this authorization at any time in writing.<br />
Inspect or copy the protected health information to be used or disclosed as permitted under federal law.<br />
Refuse to sign this authorization.<br />

<br /><strong>RISKS:</strong><br />
You understand that there is potential for your health information to be re-disclosed by the recipient, and this re-disclosure may no longer be protected by federal privacy regulations.<br />

<br /><strong>CONDITIONS:</strong><br />
AttumOrtho will not condition treatment, payment, enrollment, or eligibility for benefits on whether you sign this authorization.<br />

<br />By signing this form, you acknowledge and agree that you understand this authorization, and you are consenting to the use and disclosure of your health information by AttumOrtho as specified in this form.<br />
<br />`;

export default termsAndConditionsText;
