import React from "react";
import "./PatientPreScreen.css";
import Axios from "axios";
import { useState, useEffect, useRef } from "react";

import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PatientPreScreenQuestions from "../components/PatientPreScreenQuestions";
import { renderQuestions } from "../components/RenderQuestions";
import { getBottomNavigationUtilityClass } from "@mui/material";
import { useAuthentication } from "../auth";

function PatientPreScreenSubmissionsPage({ patientData, roleFromRoute }) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [prescreenQuestions, setQuestions] = useState(null);
  const form = useForm({
    shouldUnregister: true,
    defaultValues: patientData,
  });
  let { id } = useParams();
  const navigate = useNavigate();

  const { register, control, handleSubmit, watch, getValues } = form;

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-historian-doctor-patient-pre-screen-questions`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const response = await Axios.request(options);
          setQuestions(response.data);
        }

        // const response = await Axios.get(
        //   `${process.env.REACT_APP_BACKEND_URL}/get-historian-doctor-patient-pre-screen-questions`
        // );
        // setQuestions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuestions();
  }, []);

  const handleBackButtonClick = () => {
    if (roleFromRoute === "historian-patient-prescreen")
      navigate("/historian-patient-forms");
    else if (roleFromRoute === "doctor-patient-prescreen")
      navigate("/doctor-patient-forms");
  };

  const onSubmit = async (data) => {
    try {
      const formData = {
        case_id: { answer: id },
        ...data,
      };

      // console.log(formData);

      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
        const options = {
          method: "POST",
          url: `${process.env.REACT_APP_BACKEND_URL}/historian-doctor-patient-pre-screen-answers/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          data: formData,
        };
        const response = await Axios.request(options);
      }

      // await Axios.post(
      //   `${process.env.REACT_APP_BACKEND_URL}/historian-doctor-patient-pre-screen-answers/${id}`,
      //   formData
      // );

      // alert("New patient created!");
      toast.success("Pre-Screen Answers Updated!", {
        position: "top-center",
        autoClose: 3000, // milliseconds
      });
      // console.log("Form values submitted successfully!");

      if (roleFromRoute === "historian-patient-prescreen")
        navigate("/historian-patient-forms");
      else if (roleFromRoute === "doctor-patient-prescreen")
        navigate("/doctor-patient-forms");

      // navigate("/historian-patient-forms", { replace: true });
    } catch (error) {
      console.error("Error submitting form values:", error);
    }
  };

  return prescreenQuestions ? (
    <div className="patient-prescreen-container">
      <form autoComplete="one-time-code" onSubmit={handleSubmit(onSubmit)}>
        <div className="patient-prescreen-middle-container">
          <div className="patient-prescreen-case-info-1">
            <div className="patient-prescreen-back-button-container">
              <button
                onClick={handleBackButtonClick}
                className="patient-prescreen-back-button-1"
              >
                {"< "}Back
              </button>
            </div>
            <h1 className="patient-prescreen-case-id-text">Case ID: {id}</h1>
          </div>
          <div className="patient-prescreen-centered-content">
            <h1 className="patient-prescreen-text"> PreScreen Questions</h1>
            <div className="patient-prescreen-questions">
              {renderQuestions(prescreenQuestions.questions, form, null, false)}
            </div>
            <button
              type="submit"
              variant="primary"
              className="patient-prescreen-next-btn"
              size="lg"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default PatientPreScreenSubmissionsPage;
