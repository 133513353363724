import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./DoctorFormActions.css";
import Tabs from "../components/Tab";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuthentication } from "../auth";

function DoctorFormActions() {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const navigate = useNavigate();
  const [patientInfo, setPatientInfo] = useState({});

  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/doctor/get-patient-pre-screen-answers/${id}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const response = await axios.request(options);
          const patientData = response.data;
          setPatientInfo(patientData);
        }
        // const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/get-patient-pre-screen-answers/${id}`);
        // const patientData = response.data;
        // setPatientInfo(patientData);
      } catch (error) {
        console.error("Error fetching patient info:", error);
      }
    };

    fetchData();
  }, []);

  const errorMessageTimer = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const ERROR_TIME = 5 * 1000;

  const startErrorTimer = (message) => {
    // Clear previous timer
    clearTimeout(errorMessageTimer.current);
    setErrorMessage(message);

    errorMessageTimer.current = setTimeout(() => {
      // Display warning
      setErrorMessage(false);
    }, ERROR_TIME);
  };

  // Tracks state of all forms within patient and doctor-only questions
  const [patientDoctorCompletion, setPatientDoctorCompletion] = useState(() => {
    const initial = {};
    // '8' is hard coded. Total number of forms within that page.
    for (let i = 0; i < 8; i++) {
      initial[`accordion-${i + 1}`] = false;
    }
    return initial;
  });

  // Tracks state of all physical exam forms
  const [physicalExamCompletion, setPhysicalExamCompletion] = useState({});
  useEffect(() => {
    const fetchBodyParts = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-body-parts/${id}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const response = await axios.request(options);

          setPhysicalExamCompletion(() => {
            let result = {};
            for (const i in response.data.body_parts) {
              result = { ...result, [response.data.body_parts[i]]: false };
            }
            return result;
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchBodyParts();
  }, []);

  const checkPhysicalExamCompleted = () => {
    /*
      Success if no body parts were selected.
      If selected, you must complete ALL selected forms.
    */
    return (
      Object.keys(physicalExamCompletion).length === 0 ||
      Object.values(physicalExamCompletion).every((item) => item === true)
    );
  };

  const checkPatientDoctorCompleted = () => {
    return (
      Object.keys(patientDoctorCompletion).length > 0 &&
      Object.values(patientDoctorCompletion).every((item) => item === true)
    );
  };

  // Checks completion status of all forms
  const checkAllCompleted = () => {
    return (
      checkPatientDoctorCompleted() === true &&
      checkPhysicalExamCompleted() === true
    );
  };

  const handleActionsButtonClick = async () => {
    if (checkAllCompleted() === true) {
      const updatedData = {
        template_ready: true,
      };
      try {
        // Make a POST request to update the document with the new field
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/historian-doctor-patient-pre-screen-answers/${id}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            data: updatedData,
          };
          const response = await axios.request(options);
          navigate(`/doctor-template/${id}`);
        }
      } catch (error) {
        console.error("Error updating template_ready:", error);
      }
    } else if (checkPatientDoctorCompleted()) {
      startErrorTimer("Please complete all forms on the 'Physical Exams' tab.");
    } else if (checkPhysicalExamCompleted()) {
      startErrorTimer("Please complete all forms on the 'Questionnaire' tab.");
    } else {
      startErrorTimer("Please complete all sections before proceeding.");
    }
  };

  return (
    <div className="form-actions-container">
      <div className="back-button-container">
        <Button
          as={Link}
          to="/doctor-patient-forms"
          variant="primary"
          className="back-button"
        >
          back
        </Button>
      </div>
      <div className="info-actions-container">
        <div className="case-info">
          <h1 className="name">
            {patientInfo?.full_name?.first?.answer}{" "}
            {patientInfo?.full_name?.last?.answer}
          </h1>
          <p className="case-id">CaseID #{patientInfo?.case_id?.answer}</p>
        </div>
        <div style={{ justifyContent: "flex-end" }}>
          {errorMessage && (
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "10px",
                border: "solid 1px black",
                display: "inline",
              }}
            >
              <span style={{ fontSize: "20px", fontWeight: "600" }}>
                {errorMessage}
                <span className="error-text">&#9888;</span>
              </span>
            </div>
          )}
          <Button
            variant="Warning"
            className="actions-button"
            onClick={handleActionsButtonClick}
          >
            Generate Template
          </Button>
        </div>
      </div>
      <Tabs
        setPatientDoctorCompletion={setPatientDoctorCompletion}
        setPhysicalExamCompletion={setPhysicalExamCompletion}
        physicalExamCompletion={physicalExamCompletion}
      />
    </div>
  );
}

export default DoctorFormActions;
