import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import { AccordionSummary as MuiAccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai/";
import { ColorRing } from "react-loader-spinner";

import BodyPartsSelection from "../BodyPartsSelection";
import { useAccordion } from "./AccordionProvider";

import { useAuthentication } from "../../auth";


export default function HistorianReviewerAccordion({
  title,
  accordionid,
  onFailExport,
  setCompletion,
  sendToURL,
  fetchFromURL,
}) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  // Get props from AccordionProvider
  const { activeAccordion, setActiveAccordion, styles, incrementAccordion } =
    useAccordion();
  const expanded = activeAccordion === accordionid;

  // Custom AccordionSummary styling
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        expanded === true ? (
          <Remove sx={{ color: "white" }} />
        ) : (
          <Add sx={{ color: "white" }} />
        )
      }
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginRight: theme.spacing(3),
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      marginRight: theme.spacing(3),
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
    },
  }));

  // Completion status of form
  const [completed, setCompleted] = useState(false);
  const [submittedData, setSubmittedData] = useState("");
  // Input value
  const [historianReviewedBy, setHistorianReviewedBy] = useState("");

  // Dirty status (current selection different from last submitted)
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(historianReviewedBy !== submittedData);
  }, [historianReviewedBy]);

  // Set global form completion state
  useEffect(() => {
    setCompletion((prev) => {
      return { ...prev, [`accordion-${accordionid}`]: completed };
    });
  }, [completed]);

  // Indicates that BodyPartsSelection component is loading/fetching data
  const [progress, setProgress] = useState(false);
  const PROGRESS_SIZE = 30;

  // POST request
  const postHistorianReviewedBy = async () => {
    try {
      if (isAuthenticated) {
        // Create an object containing the data to be posted
        const data = {
          historianReviewedBy: historianReviewedBy,
        };
        setProgress(true);
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
        const options = {
          method: "POST",
          url: sendToURL,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        const response = await axios.request(options);
        setCompleted(true);
        setIsDirty(false);
        setProgress(false);
        setSubmittedData(historianReviewedBy);
        incrementAccordion();
        // console.log("Historian Reviewed By data saved successfully!");
      }
    } catch (error) {
      setProgress(false);
      console.error("Error posting Historian Reviewed By data:", error);
    }
  };

  // Set global form completion state
  useEffect(() => {
    setCompletion((prev) => {
      return { ...prev, [`accordion-${accordionid}`]: completed };
    });
  }, [completed]);

  const ref = useRef(null);

  // Scrolls to top of Accordion after expansion
  const executeScroll = () => {
    if (ref && ref.current && expanded)
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => executeScroll(), 1000);
  }, [activeAccordion]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setActiveAccordion(activeAccordion !== 9 ? 9 : 0)}
    >
      <AccordionSummary id="summary-injury" sx={styles.accordionHeader}>
        <Typography sx={styles.accordionHeaderText}>{title}</Typography>
        {progress ? (
          <ColorRing
            visible={true}
            height={PROGRESS_SIZE}
            width={PROGRESS_SIZE}
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"]}
          />
        ) : completed && !isDirty ? (
          <AiFillCheckCircle
            style={{
              color: "#059e0a",
              width: PROGRESS_SIZE,
              height: PROGRESS_SIZE,
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          />
        ) : (
          <AiFillExclamationCircle
            style={{
              color: "#b31414",
              width: PROGRESS_SIZE,
              height: PROGRESS_SIZE,
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <input
            type="text"
            className="textbox-input"
            placeholder="Historian Reviewed By"
            value={historianReviewedBy}
            onChange={(e) => {
              setHistorianReviewedBy(e.target.value);
            }}
          />
          <button
            type="submit"
            className="btn-submit-section"
            onClick={() => {
              postHistorianReviewedBy();
            }}
          >
            Save and Continue
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
