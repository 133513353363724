import React, { useState, useEffect } from "react";
import "./HistorianPatientSubmissions.css";
import DeleteConfirmation from "../components/DeleteConfirmation";
import { Table } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import HistorianPatientResponses from "./HistorianPatientFormResponses";
import PatientPreScreenSubmissionsPage from "./PatientPreScreenSubmissionsPage";
import { useAuthentication } from "../auth";

function HistorianPatientSubmissions() {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [patients, setPatients] = useState([]);
  // const [patientData, setPatientData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("recent"); // Active tab state
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedPatientData, setSelectedPatientData] = useState(null); // State for selected patient data
  const [showPatientPreScreen, setShowPatientPreScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData(); // Call fetchData every 10 seconds
    }, 10000); // 10 seconds in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  // const fetchData1 = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}/get-patient-answers`
  //     );
  //     setPatientData(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchData = async () => {
    try {
      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
        const options = {
          method: "GET",
          url: `${process.env.REACT_APP_BACKEND_URL}/get-all-patient-pre-screen-answers`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios.request(options);
        setPatients(response.data);
      }

      // const response = await axios.get(
      //   `${process.env.REACT_APP_BACKEND_URL}/get-all-patient-pre-screen-answers`
      // );
      // setPatients(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefresh = () => {
    fetchData();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = async (patient_case_id) => {
    console.log(patient_case_id);
    setSelectedPatientId(patient_case_id);
    setShowDeleteConfirmation(true);
  };

  const handleView = (patient) => {
    navigate(`/historian-patient-forms-responses/${patient.case_id.answer}`);
  };

  // const confirmDelete = async () => {
  //   try {
  //     const resp = await axios.delete(
  //       `${process.env.REACT_APP_BACKEND_URL}/deletePatient/${selectedPatientId}`
  //     );
  //     const resp1 = await axios.delete(
  //       `${process.env.REACT_APP_BACKEND_URL}/delete-patient-pre-screen/${selectedPatientId}`
  //     );

  //     const resp2 = await axios.delete(
  //       `${process.env.REACT_APP_BACKEND_URL}/delete-patient-answers/${selectedPatientId}`
  //     );

  //     const resp3 = await axios.delete(
  //       `${process.env.REACT_APP_BACKEND_URL}/delete-doctor-physical-exams/${selectedPatientId}`
  //     );

  //     const resp4 = await axios.delete(
  //       `${process.env.REACT_APP_BACKEND_URL}/delete-body-parts/${selectedPatientId}`
  //     );

  //     fetchData();
  //     console.log(resp);
  //     console.log(resp1);
  //     console.log(resp2);
  //     console.log(resp3);
  //     console.log(resp4);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   setShowDeleteConfirmation(false);
  // };

  const sendAuthorizedRequest = async (url, method, accessToken) => {
    const options = {
      method,
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return await axios.request(options);
  };

  const confirmDelete = async () => {
    try {
      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }

        const deleteEndpoints = [
          `${process.env.REACT_APP_BACKEND_URL}/deletePatient/${selectedPatientId}`,
          `${process.env.REACT_APP_BACKEND_URL}/delete-patient-pre-screen/${selectedPatientId}`,
          `${process.env.REACT_APP_BACKEND_URL}/delete-patient-answers/${selectedPatientId}`,
          `${process.env.REACT_APP_BACKEND_URL}/delete-doctor-physical-exams/${selectedPatientId}`,
          `${process.env.REACT_APP_BACKEND_URL}/delete-body-parts/${selectedPatientId}`,
        ];

        const deleteRequests = deleteEndpoints.map(async (endpoint) => {
          try {
            const response = await sendAuthorizedRequest(
              endpoint,
              "DELETE",
              accessToken
            );
            // console.log(response);
          } catch (error) {
            console.error(error);
          }
        });

        await Promise.all(deleteRequests);

        fetchData();
      }
    } catch (error) {
      console.error(error);
    }

    setShowDeleteConfirmation(false);
  };

  const handleEdit = async (patient_case_id) => {
    navigate(`/historian-patient-prescreen/${patient_case_id}`);
    // console.log("Edit clicked");
    // setShowPatientPreScreen(true);
  };

  const cancelDelete = () => {
    setSelectedPatientId(null);
    setShowDeleteConfirmation(false);
  };

  // const filteredPatients = patients.filter((patient) => {
  //   return (
  //     patient.case_id.answer.toString().includes(searchTerm) ||
  //     patient.full_name.first.answer
  //       .toLowerCase()
  //       .includes(searchTerm.toLowerCase()) ||
  //     patient.full_name.last.answer
  //       .toLowerCase()
  //       .includes(searchTerm.toLowerCase())
  //   );
  // });

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabHeader = (title, tab) => {
    return (
      <div
        className={`historian-patient-submission-tab ${
          activeTab === tab ? "active" : ""
        }`}
        onClick={() => handleTabChange(tab)}
      >
        {title}
      </div>
    );
  };

  const filterPatientsByCompletion = () => {
    return patients.filter((patient) => {
      // Check if the patient matches the search term
      const matchesSearchTerm =
        patient.case_id.answer.toString().includes(searchTerm) ||
        patient.full_name.first.answer
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        patient.full_name.last.answer
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

      // Filter by completion status (historian_completed) based on the activeTab
      if (activeTab === "recent") {
        return !patient.historian_completed && matchesSearchTerm;
      } else if (activeTab === "reviewed") {
        return patient.historian_completed && matchesSearchTerm;
      }

      return matchesSearchTerm;
    });
  };

  return (
    <div className="historian-patient-submission-setup-container">
      <div className="historian-patient-submission-small-rectangle">
        Patient Forms
      </div>
      <div className="historian-patient-submission-big-rectangle">
        <div className="historian-patient-submission-search-bar-container">
          <input
            type="text"
            className="historian-patient-submission-search-bar"
            placeholder="Search... (CaseID or FirstName or LastName)"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="historian-patient-submission-inner-rectangle">
          <div className="historian-patient-submission-tab-header">
            {renderTabHeader("Recently Submitted (Patient)", "recent")}
            {renderTabHeader("Reviewed (Historian)", "reviewed")}
          </div>
          {activeTab === "recent" && (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Case ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filterPatientsByCompletion(false).map((patient) => (
                  <tr key={patient._id}>
                    <td>{patient.case_id.answer}</td>
                    <td>{patient.full_name.first.answer}</td>
                    <td>{patient.full_name.last.answer}</td>
                    <td>
                      <button
                        className="historian-patient-submission-action-button"
                        onClick={() => handleView(patient)}
                      >
                        <strong>View</strong>
                      </button>
                      <button
                        className="historian-patient-submission-action-button"
                        onClick={() => handleEdit(patient.case_id.answer)}
                      >
                        Edit
                      </button>
                      {/* {showPatientPreScreen && <PatientPreScreenSubmissionsPage />} */}
                      <button
                        className="historian-patient-submission-action-button"
                        onClick={() => handleDelete(patient.case_id.answer)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {activeTab === "reviewed" && (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Case ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filterPatientsByCompletion(true).map((patient) => (
                  <tr key={patient._id}>
                    <td>{patient.case_id.answer}</td>
                    <td>{patient.full_name.first.answer}</td>
                    <td>{patient.full_name.last.answer}</td>
                    <td>
                      <button
                        className="historian-patient-submission-action-button"
                        onClick={() => handleView(patient)}
                      >
                        <strong>View</strong>
                      </button>
                      <button
                        className="historian-patient-submission-action-button"
                        onClick={() => handleEdit(patient.case_id.answer)}
                      >
                        Edit
                      </button>
                      <button
                        className="historian-patient-submission-action-button"
                        onClick={() => handleDelete(patient.case_id.answer)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {/* {activeTab === "recent" && (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Case ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredPatients.map((patient) => (
                  <tr key={patient._id}>
                    <td>{patient.caseID}</td>
                    <td>{patient.firstName}</td>
                    <td>{patient.lastName}</td>
                    <td>
                      <button className="action-button"><strong>View</strong></button>
                      <button className="action-button" onClick={() => handleDelete(patient._id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )} */}
          {/* {activeTab === "completed" && (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Case ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </Table>
          )} */}
        </div>
      </div>
      {/* Render the patient detail view */}
      {selectedPatientData && (
        <HistorianPatientResponses patient={selectedPatientData} />
      )}
      {/* Delete Confirmation */}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          confirmDelete={confirmDelete}
          cancelDelete={cancelDelete}
        />
      )}
    </div>
  );
}

export default HistorianPatientSubmissions;
