import React from "react";
import { getNestedValue } from "../../utils/Utils";
import "./question.css";

// indicate how many fields appear by default with "min"
export default function Note({
  register,
  registerName,
  unregister,
  getValues,
  className,
  control,
  errorData,
}) {
  const [showField, setShowField] = React.useState(
    getNestedValue(control?._defaultValues, registerName) ? true : false
  );

  const handleAdd = () => {
    setShowField(true);
  };

  const handleRemove = () => {
    setShowField(false);
    unregister(registerName);
  };

  return (
    <div
      className={"question " + className}
      style={{
        width: "100%",
        fontSize: 18,
        paddingTop: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {showField ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div style={{ width: "40%" }}>
            <label style={{ marginLeft: "8px" }}>Notes:</label>
            <span className="error-text">*</span>
            <textarea
              className={`textarea-note ${errorData && "error-input"}`}
              {...register(registerName.toLowerCase().split(" ").join("_"), {
                required: true,
              })}
            />
            {errorData && errorData?.type === "required" && (
              <span className="error-text">
                Please complete the required field OR remove the note.
              </span>
            )}
          </div>
          <button
            className="button-note"
            style={{ width: "10%" }}
            type="button"
            onClick={handleRemove}
          >
            Remove
          </button>
        </div>
      ) : (
        <button
          className="button-note"
          style={{ width: "10%" }}
          type="button"
          onClick={handleAdd}
        >
          Add Notes
        </button>
      )}
    </div>
  );
}
