import React, { useState, useEffect, useRef } from "react";
import { getNestedValue } from "../../utils/Utils";
import "./question.css";

export default function Conditional(props) {
  const {
    form,
    register,
    registerName,
    data,
    inline,
    watch,
    renderQuestions,
    className,
    number,
    note,
    setValue,
    control,
    errorData,
  } = props;

  const conditionalAnswer = `${registerName}.answer`
    .toLowerCase()
    .split(" ")
    .join("_");

  const [selectedValue, setSelectedValue] = useState(
    // if prefilling values, set conditional state to that value
    getNestedValue(control?._defaultValues, conditionalAnswer)
  );

  const { trigger } = form;

  /*
  Keeps track of conditional answer value (Yes, No, etc.). We use an event handler
  to get direct access to the component. This approach is used because there is a delay
  between selecting an answer and the form correctly reflecting that value. Because the conditional
  rendering relies on the current value of the form, there exists a race condition where
  the follow-up questions will sometimes not render unless something else triggers a rerender.
  */
  // const handleInputChange = (value) => {
  //   if (value !== selectedValue) setSelectedValue(value);
  //   /*
  //     Duct tape solution that attempts to fix bug where
  //     changing value of conditional answer does not update
  //     error state until clicking somewhere else after your selection,
  //     which prolongs the error message's lifetime, thereby confusing the user.
  //   */
  //   trigger(conditionalAnswer);
  // };

  const handleInputChange = (value) => {
    if (value !== selectedValue) {
      setSelectedValue(value);
      setValue(conditionalAnswer, value);
    }
    trigger(conditionalAnswer);
  };

  if (
    data.conditional_questions === undefined ||
    data.conditional_questions.length < 1
  ) {
    // console.log(
    //   "Invalid format. Conditional type needs at least 1 question in 'conditional_questions' field."
    // );
    return (
      <p>
        Invalid format. Conditional type needs at least 1 question in
        'conditional_questions' field.
      </p>
    );
  }

  // render every option
  function radioButton(data) {
    let radioButtons = [];

    for (let i = 0; i < data.options.length; i++) {
      radioButtons.push(
        inline === true ? (
          <div key={i} className="input-radio-container">
            <input
              className="input-radio"
              {...register(conditionalAnswer, {
                required: data.required,
              })}
              type="radio"
              value={data.options[i]}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <label>{data.options[i]}</label>
          </div>
        ) : (
          <div key={i} className={"input-radio-container"}>
            <input
              className="input-radio"
              {...register(conditionalAnswer, {
                required: data.required,
              })}
              type="radio"
              value={data.options[i]}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <label>{data.options[i]}</label>
          </div>
        )
      );
    }
    return radioButtons;
  }
  /*
    Render corresponding follow-up questions to selected answer
  */
  function renderConditionalQuestions(data) {
    // if no option selected yet
    if (!selectedValue) return;

    const questionIndexes = data.triggers[data.options.indexOf(selectedValue)];

    if (questionIndexes !== undefined && questionIndexes.length > 0) {
      let questionList = [];

      for (let i = 0; i < questionIndexes.length; i++) {
        questionList.push(data.conditional_questions[questionIndexes[i]]);
      }
      return renderQuestions(
        questionList,
        form,
        `${registerName}.conditional_questions`,
        note
      );
    }
    return;
  }

  return (
    <div
      className={"question " + className}
      style={{
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        width: "100%",
        justifyContent: "left",
        alignItems: "center",
        fontSize: 18,
      }}
    >
      <label style={{ alignSelf: "flex-start" }}>
        {number + ". " + data.question}
        {data.required === true && <span className="error-text">*</span>}
      </label>
      <div>
        <fieldset
          style={
            inline
              ? {
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: `repeat(${data.options.length}, 1fr)`,
                  border: "none",
                }
              : {
                  display: "grid",
                  alignItems: "center",
                  gridTemplateRows: `repeat(${data.options.length}, 1fr)`,
                  border: "none",
                }
          }
          aria-invalid={errorData ? "true" : "false"}
        >
          {radioButton(data)}
        </fieldset>
        {errorData && errorData?.type === "required" && (
          <span className="error-text">
            Please complete the required field.
          </span>
        )}
        {renderConditionalQuestions(data)}
      </div>
    </div>
  );
}
