import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./NavBar.css";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  NavItem,
  Button,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { useAuthentication } from "../auth"; 
  

const NavBar = () => {
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const {
    isDropdownOpen,
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    toggleDropdown,
    closeDropdown,
    logoutWithRedirect,
  } = useAuthentication();

  return (
    <nav className="navbar">
      <Link to="/" className="btn-home" style={{ fontWeight: 600 }}>
        Home
      </Link>
      <h1 className="brand">AttumOrtho</h1>
      {!isAuthenticated && (
        <NavItem>
          <Button
            id="qsLoginBtn"
            color="primary"
            className="nav-bar-log-in-btn"
            onClick={() => loginWithRedirect()}
          >
            Log in
          </Button>
        </NavItem>
      )}
      
      {isAuthenticated && (
        <div className="profile-container">
          <div className="profile-image" onClick={toggleDropdown}>
            <img
              src={user.picture}
              alt="Profile"
              className="pofile-image-image"
            />
          </div>
          {isDropdownOpen && (
            <div className="dropdown-content" onBlur={closeDropdown}>
              <div className="dropdown-header">{user.name}</div>
              <div className="dropdown-item" onClick={() => logoutWithRedirect()}>
                Log out
              </div>
            </div>
          )}
        </div>
      )}
    </nav>
  );
}

export default NavBar;
