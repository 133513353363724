import React, { useState, useEffect, useRef } from "react";
// flushSync is used to call setState functions synchronously, which we need to have the most up-to-date usePrompt value
import { flushSync } from "react-dom";
import Axios from "axios";
import "./PatientForm.css";

import Typography from "@mui/material/Typography";

import { FormControlLabel, Checkbox } from "@mui/material";
import Modal from "@mui/material/Modal";
import BoxComponent from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { AccordionProvider } from "../components/Accordion/AccordionProvider";
import FormAccordion from "../components/Accordion/FormAccordion";
import DrawingAccordion from "../components/Accordion/DrawingAccordion";
import termsAndConditionsText from "../components/TermsAndCondition";
import confirmationReleaseMedInfoText from "../components/ConfirmationReleaseMedInfo";

import usePrompt from "../utils/hooks/usePrompt";
import { destroySession } from "../utils/Utils";

export default function PatientForm({ patientFormData }) {
  const [termsOpen, setTermsOpen] = useState(true);
  const [confirmSubmissionOpen, setConfirmSubmissionOpen] = useState(false);
  const navigate = useNavigate();

  // Expire session upon reloading/leaving page
  useEffect(() => {
    const performCleanup = () => {
      // This call works for page refreshes
      destroySession();
    };

    const handleBeforeUnload = (e) => {
      e.returnValue = "";
      window.addEventListener("unload", performCleanup);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // This call works for browser back button click
      destroySession();
      window.removeEventListener("unload", performCleanup);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Warning confirmation popup when leaving page
  const [ignorePrompt, setIgnorePrompt] = useState(false);
  usePrompt(
    "Are you sure you want to leave? Your session will expire if you leave the page.",
    !ignorePrompt
  );

  // Close Terms Agreement modal
  const handleAgree = () => {
    setTermsOpen(false);
  };
  const [agreeChecked, setAgreeChecked] = useState(false);

  // set Terms Agreement checkbox
  const handleAgreeChange = (event) => {
    setAgreeChecked(event.target.checked);
  };

  const redirectToPin = () => {
    flushSync(() => {
      setIgnorePrompt(true);
    });
    navigate("/patient-pin", { replace: true });
  };
  const determineRedirectToPin = (error) => {
    if (error.response?.status === 401) {
      redirectToPin();
    }
  };

  // Mark the patient's form as completed
  const handleConfirmSubmission = async () => {
    const updatedData = {
      patient_completed: true,
    };

    try {
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/patient-pre-screen-answers/${id}`,
        updatedData,
        { withCredentials: true }
      );
      redirectToPin();
    } catch (error) {
      console.error("Error updating patient_completed:", error);
    }
  };

  const [questions, setQuestions] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/patient/get-patient-questions/`,
          { withCredentials: true }
        );

        setQuestions(response.data[0].questions);
      } catch (error) {
        console.error(error);
        determineRedirectToPin(error);
      }
    };

    fetchQuestions();
  }, []);

  // If session expires before submission, redirect to pin
  const onFailExport = (error) => {
    determineRedirectToPin(error);
  };

  // Session user idle warning popup
  const SESSION_DURATION = 20 * 60 * 1000; // 20 minutes
  const WARNING_TIME = 18 * 60 * 1000; // Show warning 2 minutes before session expires
  let sessionTimeoutWarning = useRef();
  let sessionTimeout = useRef();

  const [idleWarningOpen, setidleWarningOpen] = useState(false);
  const startSessionTimer = () => {
    // Clear previous timer
    clearTimeout(sessionTimeoutWarning.current);
    clearTimeout(sessionTimeout.current);

    sessionTimeoutWarning.current = setTimeout(() => {
      // Display warning
      setidleWarningOpen(true);
    }, WARNING_TIME);

    sessionTimeout.current = setTimeout(() => {
      // Timeout. Redirect to pin
      redirectToPin();
    }, SESSION_DURATION);
  };

  // Start session timer
  useEffect(() => {
    startSessionTimer();

    return () => {
      if (sessionTimeoutWarning.current)
        clearTimeout(sessionTimeoutWarning.current);
      if (sessionTimeout.current) clearTimeout(sessionTimeout.current);
    };
  }, []);

  // Reset session expiration time
  const renewSession = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/patient/renew-session`,
        null,
        { withCredentials: true }
      );

      if (response.status === 200) {
        setidleWarningOpen(false);
        startSessionTimer();
      }
    } catch (error) {
      console.error(error);
      determineRedirectToPin(error);
    }
  };

  // Tracks state of all forms within provider
  const [completion, setCompletion] = useState(() => {
    const initial = {};
    for (let i = 0; i < 7; i++) {
      initial[`accordion-${i + 1}`] = false;
    }
    return initial;
  });

  // Checks completion status of all forms
  const checkAllCompleted = () => {
    // console.log(completion);
    return Object.values(completion).every((item) => item === true);
  };

  const errorMessageTimer = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const ERROR_TIME = 5 * 1000;

  const startErrorTimer = () => {
    // Clear previous timer
    clearTimeout(errorMessageTimer.current);
    setErrorMessage(true);

    errorMessageTimer.current = setTimeout(() => {
      // Display warning
      setErrorMessage(false);
    }, ERROR_TIME);
  };

  return (
    <div className="patient-form-container">
      {/* Idle Warning */}
      <Modal
        open={idleWarningOpen}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
          onClick: null, // Disable closing the modal by clicking on the backdrop
        }}
      >
        <React.Fragment>
          <BoxComponent
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: 4,
              minWidth: 550,
              maxHeight: "40vh",
              overflowY: "auto",
            }}
          >
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Are you still there?
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html:
                  "Your session will expire soon due to inactivity. Please click confirm to stay logged in to this session.",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={renewSession}
            >
              Confirm
            </Button>
          </BoxComponent>
        </React.Fragment>
      </Modal>
      {/* Terms Agreement */}
      <Modal
        open={termsOpen}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
          onClick: null, // Disable closing the modal by clicking on the backdrop
        }}
      >
        <React.Fragment>
          <BoxComponent
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: 4,
              minWidth: 550,
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <Typography variant="h6" gutterBottom fontWeight="bold">
              HEALTH INFORMATION AUTHORIZATION AND RELEASE FORM
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: termsAndConditionsText }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeChecked}
                  onChange={handleAgreeChange}
                  color="primary"
                />
              }
              label="I agree to Terms and Conditions"
            />
            {agreeChecked ? (
              <Button variant="contained" color="primary" onClick={handleAgree}>
                Continue
              </Button>
            ) : null}
          </BoxComponent>
        </React.Fragment>
      </Modal>
      <AccordionProvider>
        {/* #1 Preliminary */}
        <FormAccordion
          title={"i. Preliminary"}
          questions={questions.preliminary}
          defaultValues={patientFormData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/patient-answers2/${id}`}
          onFailExport={onFailExport}
          note={false}
          setCompletion={setCompletion}
          isPatient={true}
        />
        {/* #2 Job Description */}
        <FormAccordion
          title={"ii. Job Description"}
          questions={questions.job_description}
          defaultValues={patientFormData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/patient-answers2/${id}`}
          onFailExport={onFailExport}
          note={false}
          setCompletion={setCompletion}
          isPatient={true}
        />
        {/* #3 History of Injury */}
        <FormAccordion
          title={"iii. History of Injury"}
          questions={questions.history_of_injury}
          defaultValues={patientFormData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/patient-answers2/${id}`}
          onFailExport={onFailExport}
          note={false}
          setCompletion={setCompletion}
          isPatient={true}
        />
        {/* #4 Past Medical and Family History */}
        <FormAccordion
          title={"iv. Past Medical and Family History"}
          questions={questions.past_medical_and_family_history}
          defaultValues={patientFormData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/patient-answers2/${id}`}
          onFailExport={onFailExport}
          note={false}
          setCompletion={setCompletion}
          isPatient={true}
        />
        {/* #5 Social History */}
        <FormAccordion
          title={"v. Social History"}
          questions={questions.social_history}
          defaultValues={patientFormData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/patient-answers2/${id}`}
          onFailExport={onFailExport}
          note={false}
          setCompletion={setCompletion}
          isPatient={true}
        />
        {/* #6 Subjective Pain (Body Diagram) */}
        <DrawingAccordion
          title={"vi. Subjective Pain"}
          id={id}
          sendToDrawingURL={`${process.env.REACT_APP_BACKEND_URL}/patient/body-diagram/${id}`}
          sendToFormURL={`${process.env.REACT_APP_BACKEND_URL}/patient-answers2/${id}`}
          isPatient={true}
          onFailExport={onFailExport}
          setCompletion={setCompletion}
          defaultValues={patientFormData}
          note={false}
        />
        {/* #7 Activities of Daily Living */}
        <FormAccordion
          title={"vii. Activities of Daily Living"}
          questions={questions.adl}
          defaultValues={patientFormData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/patient-answers2/${id}`}
          onFailExport={onFailExport}
          note={false}
          setCompletion={setCompletion}
          isPatient={true}
        />
      </AccordionProvider>
      <div style={{ alignSelf: "flex-end" }}>
        {errorMessage && (
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              border: "solid 1px black",
              display: "inline",
            }}
          >
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Please complete all forms before submitting{" "}
              <span className="error-text">&#9888;</span>
            </span>
          </div>
        )}
        <button
          type="button"
          className="btn-submit-section"
          onClick={() => {
            if (checkAllCompleted() === true) setConfirmSubmissionOpen(true);
            else {
              startErrorTimer();
            }
          }}
        >
          Submit Form
        </button>
      </div>
      <Modal
        open={confirmSubmissionOpen}
        onClose={() => setConfirmSubmissionOpen(false)}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <React.Fragment>
          <BoxComponent
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: 4,
              minWidth: 550,
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <Typography variant="h6" gutterBottom fontWeight="bold">
              CONFIRMATION OF RELEASE OF MEDICAL INFORMATION
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: confirmationReleaseMedInfoText,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleConfirmSubmission();
                setConfirmSubmissionOpen(false);
              }}
            >
              Submit
            </Button>
          </BoxComponent>
        </React.Fragment>
      </Modal>

      {/* <DevTool control={control} /> */}
    </div>
  );
}
