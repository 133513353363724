import "./PatientPin.scss";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import { toast } from "react-toastify";

/* Pin component provided by: https://github.com/soywod/react-pin-field */
import { PinField } from "react-pin-field";

export default function PatientPin() {
  const [pin, setPin] = useState("");
  const [pinCompleted, setPinCompleted] = useState(false);
  const [pinInvalid, setPinInvalid] = useState(false);
  const navigate = useNavigate();

  // Access PinField properties
  const ref = useRef();

  useEffect(() => {
    const validatePin = async () => {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/patient/validate-pin`,
          {
            pin: pin,
          },
          { withCredentials: true }
        );
        if (response.status === 200) {
          // Correct pin
          toast.success("Success", {
            toastId: 1,
            position: "top-center",
            autoClose: 1000,
          });
          navigate("/patient-setup");
        }
      } catch (error) {
        if (!error?.response?.status) {
          toast.error("Internal Server Error", {
            toastId: 1,
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          if (error.response.status === 401) {
            toast.error("Invalid Code", {
              toastId: 1,
              position: "top-center",
              autoClose: 2000,
            });
          } else if (error.response.status === 429) {
            toast.error("Timeout: Too Many Requests", {
              toastId: 1,
              position: "top-center",
              autoClose: 3000,
            });
          }
        }
        // Incorrect pin
        setPinCompleted(false);
        setPinInvalid(true);
      }
    };
    if (pinCompleted) {
      validatePin();
    } else {
      if (ref && ref.current) {
        // Reset pin
        ref.current.forEach((input) => (input.value = ""));
        ref.current[0].focus();
      }
    }
  }, [pinCompleted]);

  return (
    <div className="patient-pin-container">
      <div className="pin-container">
        <div className="instructions-wrapper">
          <h1 className="patient-pin-title">Patient Pin</h1>
          <p className="enter-pin-message">Enter your pin</p>
        </div>
        <div>
          <p>Only letters and numbers:</p>
          <PinField
            ref={ref}
            className={`pin-field ${pinInvalid === true ? "invalid" : ""}`}
            type="password"
            length={4}
            autoFocus
            validate={/^[a-zA-Z0-9]$/}
            autoComplete="off"
            onChange={(value) => {
              setPin(value);
              if (pinInvalid === true) setPinInvalid(false);
            }}
            onComplete={() => setPinCompleted(true)}
            disabled={pinCompleted}
          />
        </div>
      </div>
    </div>
  );
}
