import React from "react";

import "./question.css";

export default function Radio(props) {
  const {
    register,
    registerName,
    inline,
    data,
    className,
    number,
    uniform,
    getValues,
    errorData,
  } = props;

  /* Determines how many columns per row radio buttons will be displayed on */
  const columns =
    Number.isInteger(Number(data.columns)) && Number(data.columns) > 0
      ? data.columns
      : data.options.length;

  /* Render every option */
  function radioButton(data) {
    let radioButtons = [];

    for (let i = 0; i < data.options.length; i++) {
      radioButtons.push(
        inline === true ? (
          <div key={i} className={"input-radio-container-inline"}>
            <label>{data.options[i]}</label>
            <input
              className="input-radio"
              {...register(registerName.toLowerCase().split(" ").join("_"), {
                required: data.required,
              })}
              type={data.type}
              value={data.options[i]}
              aria-invalid={errorData ? "true" : "false"}
            />
          </div>
        ) : (
          <div key={i} className={"input-radio-container"}>
            <input
              className="input-radio"
              {...register(registerName.toLowerCase().split(" ").join("_"), {
                required: data.required,
              })}
              type={data.type}
              value={data.options[i]}
              aria-invalid={errorData ? "true" : "false"}
            />
            <label>{data.options[i]}</label>
          </div>
        )
      );
    }
    return radioButtons;
  }

  return (
    <div
      className={"question " + className}
      style={{
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        width: "100%",
        justifyContent: "right",
        alignItems: "center",
        fontSize: 18,
      }}
    >
      <label
        htmlFor="radio"
        style={
          inline === false ? { alignSelf: "flex-start", marginTop: "10px" } : {}
        }
      >
        {uniform === false ? `${number}. ${data.question}` : `${data.question}`}
        {data.required === true && <span className="error-text">*</span>}
      </label>
      <div>
        <fieldset
          style={
            inline
              ? {
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: `repeat(${columns}, 1fr)`,
                  border: "none",
                }
              : {
                  display: "grid",
                  alignItems: "center",
                  /*
                  bug here? Does not output desired display (outputs columns x 1. straight down) (or maybe I'm just misunderstanding it).
                */
                  gridTemplateRows: `repeat(${columns}, 1fr)`,
                  border: "none",
                }
          }
          aria-invalid={errorData ? "true" : "false"}
        >
          {radioButton(data)}
        </fieldset>
        {errorData && errorData?.type === "required" && (
          <span className="error-text">
            Please complete the required field.
          </span>
        )}
      </div>
    </div>
  );
}
