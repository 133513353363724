import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import PhysicalExams from "./PhysicalExams";
import { useAuthentication } from "../auth";

export default function PhysicalExamsDataHandler({ caseId, setCompletion }) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [physicalExamData, setPhysicalExamData] = useState(null);
  const [statusCode, setStatusCode] = useState(null); // Variable to keep track of the status code
  let { id } = useParams();
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
        const options = {
          method: "GET",
          url: `${process.env.REACT_APP_BACKEND_URL}/get-body-parts/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await Axios.request(options);
        setSelectedBodyParts(response.data.body_parts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-doctor-physical-exams/${id}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const response = await Axios.request(options);

          setPhysicalExamData(response.data.answers);
          setStatusCode(response.status); // Set the status code
        }
      } catch (error) {
        if (error.response) {
          setStatusCode(error.response.status); // Set the status code if there's an error response
        } else {
          setStatusCode("No response received"); // Set a custom message if there's no error response
        }
        console.error("Failed to fetch physical exam data: ", error);
      }
    };
    fetchData();
  }, [id]);

  // useEffect(() => {
  //   if (statusCode !== null) {
  //     console.log("Status code:", statusCode); // Log the status code whenever it changes
  //   }
  // }, [statusCode]); // Adding an effect that runs whenever the status code changes

  return statusCode === 200 && physicalExamData ? (
    <PhysicalExams
      physicalExamData={physicalExamData}
      selectedBodyParts={selectedBodyParts}
      setCompletion={setCompletion}
    />
  ) : statusCode === 404 ? (
    <PhysicalExams
      physicalExamData={null}
      selectedBodyParts={selectedBodyParts}
      setCompletion={setCompletion}
    />
  ) : (
    <div>Loading...</div>
  );
}

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let response = await Axios.get(
//           `${process.env.REACT_APP_BACKEND_URL}/get-doctor-physical-exams/${id}`
//         );

//         // Check for 200 OK status; if not 200, set data to null
//         if (response.status === 200) {
//           setPhysicalExamData(response.data);
//           console.log("Fetched data from doctor-physical-exams:", response.data);
//         } else {
//           setPhysicalExamData(null);
//           console.log("No data found for the specified case_id");
//         }
//       } catch (error) {
//         // Handle 404 or other errors by setting data to null
//         if (error.response && error.response.status === 404) {
//           setPhysicalExamData(null);
//         } else {
//           console.error("Failed to fetch physical exam data: ", error);
//         }
//       }
//     };
//     fetchData();
//   }, [id]);

//   return physicalExamData !== null ? (
//     <PhysicalExams physicalExamData={physicalExamData} selectedBodyParts={selectedBodyParts}/>
//   ) : (
//     <PhysicalExams selectedBodyParts={selectedBodyParts}/> // Render a blank form if physicalExamData is null
//   );

// use status codes and response to determine what to pass in for physicalExamData
