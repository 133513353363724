import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
    // console.log('Callback component reached');

  const { appState } = useAuth0();
  const navigate = useNavigate(); // Use useNavigate for programmatic navigation

  useEffect(() => {
    // Extract the role from appState (assuming you set it during login)
    const role = appState?.role;
    // console.log('Role:', role);

    // Determine the redirect URL based on the role
    const redirectUrl = role === 'doctor' ? '/doctor-patient-forms' : '/historian-patient-forms';
    // console.log('Redirect URL:', redirectUrl);

    // Use navigate function to redirect the user to the appropriate page
    navigate(redirectUrl);
  }, [appState, navigate]);

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default Callback;
