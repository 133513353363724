import React from "react";
import "./PatientPreScreen.css";
// flushSync is used to call setState functions synchronously, which we need to have the most up-to-date usePrompt value
import { flushSync } from "react-dom";
import Button from "@mui/material/Button";
import BoxComponent from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Axios from "axios";
import { useState, useEffect, useRef } from "react";

import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import usePrompt from "../utils/hooks/usePrompt";
import { destroySession } from "../utils/Utils";
import { toast } from "react-toastify";

import PatientPreScreenQuestions from "../components/PatientPreScreenQuestions";
import { renderQuestions } from "../components/RenderQuestions";

function PatientPreScreen() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [prescreenQuestions, setQuestions] = useState(null);
  const form = useForm({
    shouldUnregister: true,
  });
  let { id } = useParams();
  const navigate = useNavigate();

  /* 
    Determines whether the session should be destroyed or not upon leaving the page.
    The default behaviour upon leaving the page should destroy, but we need to make
    an exception when trying to navigate to '/patient-form/' or 'patient-prescreen'
    because otherwise it would cause an infinite pin validation loop.
  */
  const destroy = useRef(true);

  const shouldDestroy = () => {
    return destroy.current;
  };

  const { register, control, handleSubmit, watch, getValues } = form;

  const redirectToPin = () => {
    flushSync(() => {
      setIgnorePrompt(true);
    });
    navigate("/patient-pin", { replace: true });
  };
  const determineRedirectToPin = (error) => {
    if (error.response?.status === 401) {
      redirectToPin();
    }
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-patient-pre-screen-questions`,
          { withCredentials: true }
        );
        setQuestions(response.data);
      } catch (error) {
        console.error(error);
        determineRedirectToPin(error);
      }
    };

    fetchQuestions();
  }, []);

  const handleBackButtonClick = () => {
    // Use navigate to navigate to the desired route
    navigate("/patient-pin", { replace: true });
    // redirectToPin();
  };

  const onSubmit = async (data) => {
    try {
      const formData = {
        case_id: { answer: id },
        ...data,
      };

      // console.log(formData);

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/patient-pre-screen-answers/${id}`,
        formData,
        { withCredentials: true }
      );
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/createPatient`,
        {
          case_id: id,
        },
        { withCredentials: true }
      );
      // Set session's form id
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/set-form-id`,
        { case_id: id },
        { withCredentials: true }
      );

      // alert("New patient created!");
      toast.success("New patient created!", {
        position: "top-center",
        autoClose: 3000, // milliseconds
      });
      // 
      
      
      flushSync(() => {
        setIgnorePrompt(true);
      });
      destroy.current = false;
      navigate(`/patient-form/${id}`, { replace: true });
    } catch (error) {
      determineRedirectToPin(error);
      console.error("Error submitting form values:", error);
    }
  };

  // Expire session upon reloading/leaving page
  useEffect(() => {
    // We use fetch() with keepalive:true because it reliably sends a POST request, even when the browser/page closes
    const performCleanup = () => {
      // This call works for page refreshes
      if (shouldDestroy()) {
        destroySession();
      }
    };

    const handleBeforeUnload = (e) => {
      e.returnValue = "";
      window.addEventListener("unload", performCleanup);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // This call works for browser back button click
      if (shouldDestroy()) {
        destroySession();
      }
      window.removeEventListener("unload", performCleanup);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Warning confirmation popup when leaving page
  const [ignorePrompt, setIgnorePrompt] = useState(false);
  usePrompt(
    "Are you sure you want to leave? Your session will expire if you exit the page.",
    !ignorePrompt
  );

  // Session user idle warning popup
  const SESSION_DURATION = 20 * 60 * 1000; // 20 minutes
  const WARNING_TIME = 18 * 60 * 1000; // Show warning 2 minutes before session expires
  let sessionTimeoutWarning = useRef();
  let sessionTimeout = useRef();

  const [idleWarningOpen, setidleWarningOpen] = useState(false);
  const startSessionTimer = () => {
    // Clear previous timer
    clearTimeout(sessionTimeoutWarning.current);
    clearTimeout(sessionTimeout.current);

    sessionTimeoutWarning.current = setTimeout(() => {
      // Display warning
      setidleWarningOpen(true);
    }, WARNING_TIME);

    sessionTimeout.current = setTimeout(() => {
      // Timeout. Redirect to pin
      redirectToPin();
    }, SESSION_DURATION);
  };

  // Start session timer
  useEffect(() => {
    startSessionTimer();

    return () => {
      if (sessionTimeoutWarning.current)
        clearTimeout(sessionTimeoutWarning.current);
      if (sessionTimeout.current) clearTimeout(sessionTimeout.current);
    };
  }, []);

  // Reset session expiration time
  const renewSession = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/patient/renew-session`,
        null,
        { withCredentials: true }
      );

      if (response.status === 200) {
        setidleWarningOpen(false);
        startSessionTimer();
      }
    } catch (error) {
      console.error(error);
      determineRedirectToPin(error);
    }
  };

  return prescreenQuestions ? (
    <div className="patient-prescreen-container">
      {/* Idle Warning */}
      <Modal
        open={idleWarningOpen}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
          onClick: null, // Disable closing the modal by clicking on the backdrop
        }}
      >
        <React.Fragment>
          <BoxComponent
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: 4,
              minWidth: 550,
              maxHeight: "40vh",
              overflowY: "auto",
            }}
          >
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Are you still there?
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html:
                  "Your session will expire soon due to inactivity. Please click confirm to stay logged in to this session.",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={renewSession}
            >
              Confirm
            </Button>
          </BoxComponent>
        </React.Fragment>
      </Modal>
      <form autoComplete="one-time-code" onSubmit={handleSubmit(onSubmit)}>
        <div className="patient-prescreen-middle-container">
          <div className="patient-prescreen-case-info-1">
            <div className="patient-prescreen-back-button-container">
              <button
                onClick={handleBackButtonClick}
                className="patient-prescreen-back-button-1"
              >
                {"< "}Back
              </button>
            </div>
            <h1 className="patient-prescreen-case-id-text">Case ID: {id}</h1>
          </div>
          <div className="patient-prescreen-centered-content">
            <h1 className="patient-prescreen-text"> PreScreen Questions</h1>
            <div className="patient-prescreen-questions">
              {renderQuestions(prescreenQuestions.questions, form, null, false)}
            </div>
            <button
              type="submit"
              variant="primary"
              className="patient-prescreen-next-btn"
              size="lg"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default PatientPreScreen;
