import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import HistorianPatientResponses from "./HistorianPatientFormResponses";
import { useAuthentication } from "../auth";

export default function PatientDataHandler() {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [patientData, setPatientData] = useState(null);
  let { id } = useParams();
  // Conditionally render form once ALL API calls are finished
  const [loading, setLoading] = useState(true); // Initialize loading to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        let isHistorianDoctorData = true;
        let isPatientData = true;

        // Check if the case_id exists in the historian-answers collection
        try {
          if (isAuthenticated) {
            let accessToken;
            try {
              accessToken = await getAccessTokenSilently();
            } catch (error) {
              console.error("Error fetching access token:", error);
            }
            const options = {
              method: "GET",
              url: `${process.env.REACT_APP_BACKEND_URL}/get-historian-doctor-answers/${id}`,
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            };
            response = await Axios.request(options);
          }
        } catch (error) {
          isHistorianDoctorData = false;
        }

        // If case_id exists in historian-doctor-answers, use that data; otherwise, use patient-answers
        if (isHistorianDoctorData) {
          setPatientData(response.data.answers);
          // console.log("Fetched data from historian-answers:", response.data);
        } else {
          try {
            if (isAuthenticated) {
              let accessToken;
              try {
                accessToken = await getAccessTokenSilently();
              } catch (error) {
                console.error("Error fetching access token:", error);
              }
              const options = {
                method: "GET",
                url: `${process.env.REACT_APP_BACKEND_URL}/get-patient-answers/${id}`,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              };

              response = await Axios.request(options);
            }
            // Duplicate patient answers to historian-doctor collection. Need for template generation (duct tape fix?)
            if (isAuthenticated) {
              let accessToken;
              try {
                accessToken = await getAccessTokenSilently();
              } catch (error) {
                console.error("Error fetching access token:", error);
              }
              const options = {
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                data: response.data.answers,
              };
              await Axios.request(options);
            }
            setPatientData(response.data.answers);
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false once all API calls are done
      }
    };
    fetchData();
  }, [id]);

  // useEffect(() => {
  //   // Log the value only when patientData is not null or undefined
  //   if (patientData !== null && typeof patientData !== "undefined") {
  //     console.log("PRINT", patientData);
  //   }
  // }, [patientData]);

  // return <HistorianPatientResponses patientData={patientData} />;
  return loading ? (
    <div>Loading...</div>
  ) : (
    <HistorianPatientResponses patientData={patientData} />
  );
}
