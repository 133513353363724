import React from "react";

import "./question.css";

export default function Scale(props) {
  const {
    register,
    registerName,
    data,
    className,
    number,
    uniform,
    errorData,
  } = props;

  const columns = data.options.length;

  /* Render every option */
  function radioButton(data) {
    let radioButtons = [];

    for (let i = 0; i < data.options.length; i++) {
      radioButtons.push(
        <div
          key={i}
          style={{ justifySelf: "center" }}
          className={"input-radio-container-inline"}
        >
          <label>{data.options[i]}</label>
          <input
            className="input-radio"
            {...register(registerName.toLowerCase().split(" ").join("_"), {
              required: data.required,
            })}
            type="radio"
            value={data.options[i]}
          />
        </div>
      );
    }
    return radioButtons;
  }

  return (
    <div
      className={"question " + className}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        fontSize: 18,
      }}
    >
      <label>
        {uniform === false ? `${number}. ${data.question}` : `${data.question}`}
        {data.required === true && <span className="error-text">*</span>}
      </label>
      <fieldset
        style={{
          marginTop: "30px",
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          border: "none",
        }}
        aria-invalid={errorData ? "true" : "false"}
      >
        {radioButton(data)}
      </fieldset>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          fontSize: "15px",
          fontStyle: "italic",
        }}
      >
        <p
          className={"question " + className}
          style={{ width: "40%", textAlign: "left" }}
        >{`${data.low_end}`}</p>
        <p
          className={"question " + className}
          style={{ width: "40%", textAlign: "right" }}
        >{`${data.high_end}`}</p>
      </div>
      {errorData && errorData?.type === "required" && (
        <span className="error-text">Please complete the required field.</span>
      )}
    </div>
  );
}
