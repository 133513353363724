const confirmationReleaseMedInfoText = `
By pressing the "Submit" button, I (the patient), confirm my understanding and consent regarding the following stipulations:
<br />
<br /><strong>Voluntary Release:</strong><br />
I am willfully and consciously providing my detailed medical examination data to Dr. Attum's Clinic. This data will be subsequently stored and managed via the AttumOrtho electronic medical records (EMR) system.
<br />
<br /><strong>Data Storage and Usage:</strong><br />
My medical examination data, once entered into AttumOrtho, will be stored, processed, and accessed by authorized personnel of Dr. Attum's Clinic for medical, diagnostic, and therapeutic purposes directly related to my healthcare and treatment.
<br />
<br /><strong>Previous Consent Acknowledgement:</strong><br />
I am aware that at the outset of my relationship with Dr. Attum's Clinic, I granted permission for the collection, usage, and storage of my medical information. This specific acknowledgment serves as a reiteration of that primary consent, particularly for data provided through AttumOrtho.
<br />
<br /><strong>Irreversibility of Submission:</strong><br />
Once I confirm and send my medical examination data by pressing "Submit", any included information becomes an integral part of my medical record at Dr. Attum's Clinic. I comprehend that post-submission alterations or retractions will not be straightforward and will necessitate a separate, potentially intricate, procedure as delineated by the clinic's policies.
<br />
<br /><strong>Accuracy Assurance:</strong><br />
I attest to the truthfulness, accuracy, and completeness of the medical data I am providing. I understand the crucial role of this data in shaping my treatment decisions and acknowledge my responsibility in ensuring its correctness.
<br />
<br />
By pressing "Submit," I solemnly affirm my comprehensive understanding of, and agreement with, the aforementioned statements and conditions.
<br />
<br />`;

export default confirmationReleaseMedInfoText;