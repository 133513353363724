import "./App.css";
import { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import Axios from "axios";
import Home from "./pages/Home";
import DoctorLogin from "./pages/DoctorLogin";
import PatientPin from "./pages/PatientPin";
import PatientSetup from "./pages/PatientSetup";
import PatientForm from "./pages/PatientForm";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import DoctorPatientForms from "./pages/DoctorPatientForms";
import DoctorFormActions from "./pages/DoctorFormActions";
import DoctorTemplate from "./pages/DoctorTemplate";
import HistorianLogin from "./pages/HistorianLogin";
// import HistorianPatientForms from "./pages/HistorianPatientForms";
import HistorianPatientSubmissions from "./pages/HistorianPatientSubmissions";
import HistorianPatientDataHandler from "./pages/HistorianPatientDataHandler";
import ReactErrorBoundary from "./pages/ErrorBoundary";
import ErrorPage from "./pages/ErrorPage";
import PatientPreScreen from "./pages/PatientPreScreen";
import PatientFormDataHandler from "./pages/PatientFormDataHandler";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
import RouteGuard from "./RouteGuard";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import PatientPreScreenSubmissions from "./pages/PatientPreScreenSubmissionsPage";
import PatientPreScreenSubmissionsPageDataHandler from "./pages/PatientPreScreenSubmissionsPageDataHandler";
import NavBar from "./components/NavBar";

import Callback from "./components/CallBack";

export default function App() {
  const [listOfUsers, setListOfUsers] = useState([]);

  return (
    <ThemeProvider theme={theme}>
      <div className="page-container">
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
        <NavBar />

        <Routes>
          {/* Patient Routes */}
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/patient-pin" element={<PatientPin />} />
          <Route path="/patient-prescreen/:id" element={<PatientPreScreen />} />
          <Route path="/patient-setup" element={<PatientSetup />} />
          <Route
            path="/patient-form/:id"
            element={<PatientFormDataHandler />}
          />

          {/* Historian Routes */}
          <Route
            path="/historian-patient-forms"
            element={
              <RouteGuard accessLevel="historian">
                <HistorianPatientSubmissions />
              </RouteGuard>
            }
          />
          <Route
            path="/historian-patient-prescreen/:id"
            element={
              <RouteGuard accessLevel="historian">
                <PatientPreScreenSubmissionsPageDataHandler />
              </RouteGuard>
            }
          />
          <Route
            path="/historian-patient-forms-responses/:id"
            element={
              <RouteGuard accessLevel="historian">
                <HistorianPatientDataHandler />
              </RouteGuard>
            }
          />

          {/* Doctor Routes */}
          <Route
            path="/doctor-patient-prescreen/:id"
            element={
              <RouteGuard accessLevel="doctor">
                <PatientPreScreenSubmissionsPageDataHandler />
              </RouteGuard>
            }
          />
          <Route
            path="/doctor-patient-forms"
            element={
              <RouteGuard accessLevel="doctor">
                <DoctorPatientForms />
              </RouteGuard>
            }
          />
          <Route
            path="/doctor-form-actions/:id"
            element={
              <RouteGuard accessLevel="doctor">
                <DoctorFormActions />
              </RouteGuard>
            }
          />
          <Route
            path="/doctor-template/:id"
            element={
              <RouteGuard accessLevel="doctor">
                <DoctorTemplate />
              </RouteGuard>
            }
          />
          <Route path="/access-denied" element={<AccessDeniedPage />} />

          {/* Patient Routes (only added here for ErrorPage issue but properly initilaized and called above) */}
          {/* <Route index />
          <Route path="/home" />
          <Route path="/patient-pin" />
          <Route path="/patient-setup" />
          <Route path="/patient-prescreen/:id" />
          <Route path="/patient-form/:id" /> */}

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: `"Inter", sans-serif`,
  },
});
