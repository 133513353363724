import React, { useState, useEffect } from "react";
import { getNestedValue } from "../../utils/Utils";
import "./TableForm.css";
import "./question.css";

export default function TableForm({
  data,
  registerName,
  register,
  setValue,
  className,
  errorData,
  number,
  uniform,
}) {
  // rows, columns, title, autofilledColumns

  const { rows, columns, title, autofilledColumns } = data;

  /* Initial table data. After initially loaded, input state is handled by React Hook Form. */
  const [tableData, setTableData] = useState([]);

  /*
   Indexes of autofilled columns to use for
   determining input readOnly attribute
  */
  const [autofilledColumnIndexes, setautofilledColumnIndexes] = useState([]);
  const [autofillExists, setAutofillExists] = useState(false);

  useEffect(() => {
    if (autofilledColumns.length !== 0) {
      setAutofillExists(true)
    }

      const autofilledIndexArray = [];

      const initialData = rows.map((rowName) => {
        const values = new Array(columns.length).fill("");
        autofilledColumns.forEach((column) => {
          const { columnName, valuesList } = column;
          const columnIndex = columns.indexOf(columnName);

          if (columnIndex !== -1) {
            values[columnIndex] = valuesList[rowName] || "";
            autofilledIndexArray.push(columnIndex);
          }
        });
        return { rowName, values };
      });
      setTableData(initialData);
      setautofilledColumnIndexes(autofilledIndexArray);
  }, [rows, columns, autofilledColumns]);


  // Function to handle the autofill action
  const handleAutofill = () => {
    const newData = rows.map((rowName, rowIndex) => {
      const values = new Array(columns.length).fill("");
  
      // determine the autofilled value for that row
      const autofilledValue = autofilledColumns[0].valuesList[rowName];
  
      columns.forEach((columnName, columnIndex) => {
        values[columnIndex] = autofilledValue;
  
        // Update the form state for each field
        const fieldName = `${registerName}.${rowIndex}.${columnIndex}`
                          .toLowerCase()
                          .split(" ")
                          .join("_");
        setValue(fieldName, autofilledValue);
      });
  
      return { rowName, values };
    });
  
    setTableData(newData);
  };

  return (
    <div className={`table-form-container ${className}`}>
      <label className="question">
        {uniform === false ? `${number}. ` : ""}
        {data.question ? data.question : ""}
        {data.required === true && <span className="error-text">*</span>}
      </label>
      <table className="table-form">
        <thead>
          <tr>
            <th>{title}</th>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.rowName}</td>
              {row.values.map((cell, columnIndex) => {
                const registerNameCopy =
                  `${registerName}.${rowIndex}.${columnIndex}`
                    .toLowerCase()
                    .split(" ")
                    .join("_");
                const errorDataNested = getNestedValue(
                  errorData,
                  `${rowIndex}.${columnIndex}`
                    .toLowerCase()
                    .split(" ")
                    .join("_")
                );

                return (
                  <td
                    key={columnIndex}
                    className={errorDataNested ? "error-input" : ""}
                  >
                    <input
                      type="text"
                      className={className}
                      defaultValue={cell}
                      readOnly={autofilledColumnIndexes.includes(columnIndex)}
                      {...register(registerNameCopy, {
                        required: data.required,
                      })}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
          <div className="table-form-button-container">
          {autofillExists && (
            <button type="button" onClick={handleAutofill} className="table-form-autofill-btn">
              Autofill Table
            </button>
          )}
          </div>
        </tbody>
      </table>
      {errorData && (
        <span
          className="error-text"
          style={{ display: "block", marginTop: "10px" }}
        >
          Please fill all boxes.
        </span>
      )}
    </div>
  );
}
