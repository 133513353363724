import React from "react";
import "./Home.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthentication } from "../auth";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { handleRedirectByRole } = useAuthentication();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/patient-pin");
  };

  return (
    <div className="home-container">
      <div className="select-role-container">
        <div className="title-wrapper">
          <h1 className="select-role-title">Welcome to AttumOrtho</h1>
          <p className="select-role-message">Please select your role</p>
        </div>
        <div className="button-container">
          <Button
            onClick={handleButtonClick}
            variant="primary"
            className="select-role-btn"
          >
            Patient
          </Button>
          <Button
            // as={Link}
            variant="primary"
            className="select-role-btn"
            onClick={() => handleRedirectByRole("historian")}
          >
            Historian
          </Button>
          <Button
            // as={Link}
            variant="primary"
            className="select-role-btn"
            onClick={() => handleRedirectByRole("doctor")}
          >
            Doctor
          </Button>
        </div>
      </div>
    </div>
  );
}
