import "./Legend.css";

export default function Legend({ addShape, idCounter, ...props }) {
  const interactive = props.interactive === true ? true : false;
  const addShapeButton = (shape) => {
    return interactive === true ? (
      <button className="btn-legend" onClick={() => addShape(shape, idCounter)}>
        +
      </button>
    ) : (
      <div style={{ marginTop: "5px", height: "30px" }}></div>
    );
  };
  return (
    <div id="container-legend">
      <p id="title-legend">Legend</p>
      <div id="container-btn">
        <div className="shape-block">
          <span>Numbness</span>
          <span className="pain-type" style={{ fontWeight: "900" }}>
            ----
          </span>
          {addShapeButton("dash")}
        </div>
        <div className="shape-block">
          <span>Constant Throbbing Ache</span>
          <span className="pain-type">XXX</span>
          {addShapeButton("cross")}
        </div>

        <div className="shape-block">
          <span>Increased Sensitivity</span>
          <span className="pain-type">000</span>
          {addShapeButton("circle")}
        </div>
        <div className="shape-block">
          <span>Sharpe Twinge with Motion</span>
          <span className="pain-type">/ / / /</span>
          {addShapeButton("slash")}
        </div>
        <div id="separator"></div>
        <div className="shape-block">
          <span>
            Pain <u>ON SKIN</u>
          </span>
          <span className="pain-location">S</span>
          {addShapeButton("s")}
        </div>
        <div className="shape-block">
          <span>
            Pain <u>IN BODY</u>
          </span>
          <span
            className="pain-location"
            style={{
              fontFamily: "Roboto Mono",
            }}
          >
            I
          </span>
          {addShapeButton("i")}
        </div>

        <div className="shape-block">
          <span>
            Pain ON SKIN <u>AND</u> IN BODY
          </span>
          <span className="pain-location">B</span>
          {addShapeButton("b")}
        </div>
      </div>
    </div>
  );
}
