import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import "./PatientForm.css";

import { useAuthentication } from "../auth";

import { AccordionProvider } from "../components/Accordion/AccordionProvider";
import FormAccordion from "../components/Accordion/FormAccordion";
import DrawingAccordion from "../components/Accordion/DrawingAccordion";

// import sample from "../SampleQuestions.js";
// import DoctorOnlyQuestions from "../components/DoctorOnlyQuestions";

export default function DoctorPatientFormResponses({
  patientData,
  doctorOnlyResponses,
  setCompletion,
}) {
  const [questions, setQuestions] = useState({});
  const [DoctorOnlyQuestions, setDoctorOnlyQuestions] = useState([]);
  let { id } = useParams();
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-patient-questions`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          const response = await Axios.request(options);
          setQuestions(response.data[0].questions);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDoctorOnlyQuestions = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-doctor-only-questions`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          const response = await Axios.request(options);
          setDoctorOnlyQuestions(response.data);
        }
      } catch (error) {
        console.error("Error fetching doctor only questionsss: ", error);
      }
    };

    fetchQuestions();
    fetchDoctorOnlyQuestions();
  }, []);

  return (
    <div className="patient-form-container">
      {/* #1 Preliminary */}
      <AccordionProvider>
        <FormAccordion
          title={"i. Preliminary"}
          questions={questions.preliminary}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #2 Job Description */}
        <FormAccordion
          title={"ii. Job Description"}
          questions={questions.job_description}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #3 History of Injury */}
        <FormAccordion
          title={"iii. History of Injury"}
          questions={questions.history_of_injury}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #4 Past Medical and Family History */}
        <FormAccordion
          title={"iv. Past Medical and Family History"}
          questions={questions.past_medical_and_family_history}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #5 Social History */}
        <FormAccordion
          title={"v. Social History"}
          questions={questions.social_history}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #6 Subjective Pain (Body Diagram) */}
        <DrawingAccordion
          title={"vi. Subjective Pain"}
          id={id}
          isImage={true}
          setCompletion={setCompletion}
          sendToFormURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          defaultValues={patientData}
          note={true}
        />
        {/* #7 Activities of Daily Living */}
        <FormAccordion
          title={"vii. Activities of Daily Living"}
          questions={questions.adl}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #8 Questions for Doctor */}
        <FormAccordion
          title={"viii. Questions for Doctor"}
          questions={DoctorOnlyQuestions}
          defaultValues={doctorOnlyResponses}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/doctor-only-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
      </AccordionProvider>
    </div>
  );
}
