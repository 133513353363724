import React, { useState, useEffect } from "react";
import Axios from "axios";
import axios from "axios";
import "./BodyPartsSelection.css";
import { toast } from "react-toastify";
import { useAuthentication } from "../auth";

function BodyPartsSelection({
  sendToURL,
  fetchFromURL,
  setProgress,
  setCompleted,
  setIsDirty,
  onSuccessfulSubmit,
  setCompletion,
  completion,
}) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  // Contains current state of form during submission. Updates upon submission.
  const [submittedData, setSubmittedData] = useState([]);
  /* 
    Different from progress in that 'progress' refers to submitting process
    whereas 'loading' refers to fetching process
  */
  const [loading, setLoading] = useState(true);

  const bodyParts = [
    "C Spine",
    "Elbows",
    "Hands",
    "Hips",
    "Knees",
    "Lumbosacral Spine",
    "Shoulder",
    "Thoracic Spine",
    "Ankle/Feet",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: fetchFromURL,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const response = await Axios.request(options);
          setSelectedBodyParts(response.data.body_parts);
          setLoading(false);

          if (setCompleted !== undefined) setCompleted(true);
          setSelectedBodyParts(response.data.body_parts);
          setSubmittedData(response.data.body_parts);
          if (setProgress !== undefined) setProgress(false);
          if (setLoading !== undefined) setLoading(false);

          // Update physical exam form completion status (add/remove fields)
          if (setCompletion !== undefined) {
            for (const i in response.data.body_parts) {
              if (!completion.hasOwnProperty(response.data.body_parts[i])) {
                // Add selected body part to form completion tracker as new field if it is not present
                // console.log("Insert:" + response.data.body_parts[i]);
                setCompletion((prev) => {
                  return { ...prev, [response.data.body_parts[i]]: false };
                });
              }
            }
            // Remove body part form from completion tracker if it is not a selected body part
            for (const bodyPart in completion) {
              if (!response.data.body_parts.includes(bodyPart)) {
                // console.log("Delete: " + bodyPart);
                setCompletion((prev) => {
                  const updated = prev;
                  delete updated[bodyPart];
                  return updated;
                });
              }
            }
          }
        }
      } catch (error) {
        if (setLoading !== undefined) setLoading(false);
        if (setProgress !== undefined) setProgress(false);
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedBodyParts((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedBodyParts((prevSelected) =>
        prevSelected.filter((bodyPart) => bodyPart !== value)
      );
    }
  };

  const compareArrays = (array1, array2) => {
    const array2Sorted = array2.slice().sort();
    return (
      array1.length === array2.length &&
      array1
        .slice()
        .sort()
        .every(function (value, index) {
          return value === array2Sorted[index];
        })
    );
  };

  // Sets dirty status upon checkbox select
  useEffect(() => {
    if (setIsDirty !== undefined)
      setIsDirty(!compareArrays(selectedBodyParts, submittedData));
  }, [selectedBodyParts]);

  const submitBodyParts = async () => {
    try {
      if (setProgress !== undefined) setProgress(true);
      const bodyPartsData = {
        body_parts: selectedBodyParts,
      };

      // Post form values to the backend API endpoint
      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
        const options = {
          method: "POST",
          url: sendToURL,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          data: bodyPartsData,
        };
        const response = await Axios.request(options);

        if (setCompleted !== undefined) setCompleted(true);
        if (setIsDirty !== undefined) setIsDirty(false);
        setSubmittedData(bodyPartsData);
        if (setProgress !== undefined) setProgress(false);
        if (onSuccessfulSubmit !== undefined) onSuccessfulSubmit();

        // Update physical exam form completion status (add/remove fields)
        if (setCompletion !== undefined) {
          for (const i in selectedBodyParts) {
            if (!completion.hasOwnProperty(selectedBodyParts[i])) {
              // Add selected body part to form completion tracker as new field if it is not present
              setCompletion((prev) => {
                return { ...prev, [selectedBodyParts[i]]: false };
              });
            }
          }
          // Remove body part form from completion tracker if it is not a selected body part
          for (const bodyPart in completion) {
            if (!selectedBodyParts.includes(bodyPart)) {
              // console.log("Delete: " + bodyPart);
              setCompletion((prev) => {
                const updated = prev;
                delete updated[bodyPart];
                return updated;
              });
            }
          }
        }
        // Display a success toast notification
        toast.success("Body parts submitted successfully!", {
          position: "top-center",
          autoClose: 3000, // milliseconds
        });
        // console.log("Form values submitted successfully!");
      }
    } catch (error) {
      console.error("Error submitting form values:", error);
    }
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="body-parts-container">
          <div style={{ display: "inline-flex", flexWrap: "wrap" }}>
            {bodyParts.map((bodyPart) => (
              <div key={bodyPart} className="body-part-item">
                <input
                  type="checkbox"
                  id={bodyPart}
                  value={bodyPart}
                  checked={selectedBodyParts.includes(bodyPart)}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={bodyPart}>{bodyPart}</label>
              </div>
            ))}
          </div>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="submit"
          className="body-parts-submit-btn"
          onClick={submitBodyParts}
        >
          Submit Body Parts
        </button>
      </div>
    </div>
  );
}

export default BodyPartsSelection;
