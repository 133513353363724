import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";

import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import { AccordionSummary as MuiAccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai/";
import { ColorRing } from "react-loader-spinner";

import { useForm } from "react-hook-form";
import { renderQuestions } from "../RenderQuestions";

import Drawing from "../questions/Drawing/Drawing";

import { useAccordion } from "./AccordionProvider";
import { useAuthentication } from "../../auth";

export default function DrawingAccordion({
  title,
  accordionid,
  id,
  sendToDrawingURL,
  sendToFormURL,
  onFailExport,
  isImage,
  isPatient,
  setCompletion,
  defaultValues,
  note,
}) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  // Get props from AccordionProvider
  const { activeAccordion, setActiveAccordion, styles, incrementAccordion } =
    useAccordion();
  const expanded = activeAccordion === accordionid;

  // progress indicator when submitting section
  const [progress, setProgress] = useState(isImage === true);
  const PROGRESS_SIZE = 30;

  // If on historan/doctor side, load patient's image instead
  const [bodyDiagramSrc, setbodyDiagramSrc] = useState(null);

  useEffect(() => {
    if (isImage === true) {
      const fetchBodyDiagram = async () => {
        try {
          if (isAuthenticated) {
            let accessToken;
            try {
              accessToken = await getAccessTokenSilently();
            } catch (error) {
              console.error("Error fetching access token:", error);
            }
            const options = {
              method: "GET",
              url: `${process.env.REACT_APP_BACKEND_URL}/patient/body-diagram/${id}`,
              responseType: "arraybuffer",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            };

            const response = await Axios.request(options);
            const base64 = window.btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            setbodyDiagramSrc(`data:image/png;base64,${base64}`);
          }
        } catch (error) {
          console.error("Error fetching the image", error);
        }
      };
      fetchBodyDiagram();
      setProgress(false);
    }
  }, []);

  // Custom AccordionSummary styling
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        // Subjective Pain is 6th accordion at the moment
        activeAccordion === props.accordionid ? (
          <Remove sx={{ color: "white" }} />
        ) : (
          <Add sx={{ color: "white" }} />
        )
      }
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginRight: theme.spacing(3),
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      marginRight: theme.spacing(3),
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
    },
  }));

  const drawingRef = useRef(null);

  // Track completion state
  const [completed, setCompleted] = useState(isImage === true);

  // Set global form completion state
  useEffect(() => {
    setCompletion((prev) => {
      return { ...prev, [`accordion-${accordionid}`]: completed };
    });
  }, [completed]);

  // Contains current state of form during submission. Updates upon submission.
  const [submittedData, setSubmittedData] = useState(defaultValues);

  // Create form
  const form = useForm({
    shouldUnregister: true,
    defaultValues: defaultValues || {},
    mode: "all",
  });

  const {
    handleSubmit,
    watch,
    getValues,
    formState,
    trigger,
    reset,
    clearErrors,
  } = form;

  const { errors, isValid, isDirty, isSubmitSuccessful } = formState;
  const [isDrawingDirty, setIsDrawingDirty] = useState(!isImage);
  useEffect(() => {
    if (isSubmitSuccessful) {
      /* 
        Set current form values as default values.
        We will use this to detect if the form state is dirty 
        for the sake of marking form completion. If dirty, set form completion to false.
        Dirty in this case means at least one of the fields 
        does not match its default value (last submitted values).
      */
      reset(getValues());
    }
  }, [submittedData]);

  const clearErrorsTimer = useRef(null);

  // Check if form is already filled. If so, mark as complete
  useEffect(() => {
    const checkAlreadyCompleted = async () => {
      const _ = form.formState.errors;
      await form.trigger();

      if (isValid === true && Object.keys(errors).length === 0) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    };

    checkAlreadyCompleted();
    setProgress(false);
    /*
      Clear errors after calling trigger. 
      Need a slight delay before clearing errors 
      or it'll execute too early and not actually clear.
    */
    clearTimeout(clearErrorsTimer.current);
    clearErrorsTimer.current = setTimeout(() => {
      if (clearErrors) clearErrors();
    }, 1000);
  }, []);

  const submitDrawing = async () => {
    if (isImage !== true) {
      // capture current state of Drawing component into an image
      const image = await drawingRef.current.captureImageFromCanvas(3);

      if (image) {
        // send image to backend
        const sendImageToServer = async (dataUrl) => {
          const blob = await fetch(dataUrl).then((res) => res.blob());
          const formData = new FormData();

          formData.append("file", blob, `body-diagram-${id}.png`); // "file" is the field name, ensure it matches the backend expected field name.

          // No need for useAuthentication because patient-side authentication is session-based
          return await Axios.post(sendToDrawingURL, formData, {
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
          });
        };
        await sendImageToServer(image);
      }
    }
  };

  const submitForm = async (data) => {
    if (isPatient === true) {
      await Axios.post(sendToFormURL, data, {
        withCredentials: true,
      });
    } else {
      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
          throw new Error("Failed to fetch access token");
        }

        const options = {
          method: "POST",
          url: sendToFormURL, // Replace with the appropriate URL
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          data: data, // Use the data from the function parameter
        };

        await Axios.request(options);
      } else {
        console.error("User is not authenticated. Cannot make the request.");
      }
    }
  };

  // Handle Form submit
  const onSubmit = async (data) => {
    try {
      setProgress(true);

      // POST Drawing and Additional Comments
      await submitForm(data);
      if (!isImage) await submitDrawing();

      setSubmittedData(data);
      setProgress(false);
      setCompleted(true);
      setIsDrawingDirty(false);
      incrementAccordion();
    } catch (error) {
      if (onFailExport)
        // Run given function on failure of submission
        onFailExport(error);
      console.error("Error submitting:", error);
      setProgress(false);
    }
  };

  const ref = useRef(null);

  // Completion status of form
  useEffect(() => {
    setCompleted(isValid);
  }, [isValid]);

  // Scrolls to top of Accordion after expansion
  const executeScroll = () => {
    if (ref && ref.current && activeAccordion === accordionid)
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => executeScroll(), 1000);
  }, [activeAccordion]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() =>
        setActiveAccordion(activeAccordion !== accordionid ? accordionid : 0)
      }
      ref={ref}
    >
      <AccordionSummary accordionid={accordionid} sx={styles.accordionHeader}>
        <Typography sx={styles.accordionHeaderText}>{title}</Typography>
        {progress ? (
          <ColorRing
            visible={true}
            height={PROGRESS_SIZE}
            width={PROGRESS_SIZE}
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"]}
          />
        ) : completed && !isDirty && !isDrawingDirty ? (
          <AiFillCheckCircle
            style={{
              color: "#059e0a",
              width: PROGRESS_SIZE,
              height: PROGRESS_SIZE,
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          />
        ) : (
          <AiFillExclamationCircle
            style={{
              color: "#b31414",
              width: PROGRESS_SIZE,
              height: PROGRESS_SIZE,
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          />
        )}
      </AccordionSummary>
      {isImage ? (
        <AccordionDetails sx={{ padding: 0 }}>
          <div
            style={{
              backgroundColor: "#d5d5d5",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography style={{ textAlign: "center", margin: "20px 0px" }}>
              The following image is a{" "}
              <strong>
                <u>SCREENSHOT</u>
              </strong>{" "}
              and is{" "}
              <strong>
                <u>NOT</u>
              </strong>{" "}
              interactive.
            </Typography>
            {/* Image for body diagram */}
            <div
              style={{
                width: "85%",
                aspectRatio: "16 / 9",
                border: "1px solid black",
                alignSelf: "center",
              }}
            >
              {bodyDiagramSrc && (
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={bodyDiagramSrc}
                  alt="Current Patient's Submitted Body Diagram"
                />
              )}
            </div>
            <form
              autoComplete="one-time-code"
              onSubmit={handleSubmit(onSubmit)}
            >
              {renderQuestions(
                [
                  {
                    question_id: "body_diagram_additional_comments",
                    structure: {
                      question: "Additional Comments:",
                      type: "textarea",
                      required: false,
                    },
                  },
                ],
                form,
                null,
                note === true ? true : false
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <ColorRing
                  visible={progress}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#2164ec",
                    "#2164ec",
                    "#2164ec",
                    "#2164ec",
                    "#2164ec",
                  ]}
                />
                <button // changed from input
                  type="submit"
                  className="btn-submit-section"
                  style={{ alignSelf: "center" }}
                  // onClick={handleNextSection}
                >
                  Save and Continue
                </button>
              </div>
            </form>
          </div>
        </AccordionDetails>
      ) : (
        <AccordionDetails sx={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#d5d5d5",
            }}
          >
            {/* Interactive Drawing */}
            <Drawing
              ref={drawingRef}
              interactive={true}
              setIsDirty={setIsDrawingDirty}
            />
            <form
              autoComplete="one-time-code"
              onSubmit={handleSubmit(onSubmit)}
            >
              {renderQuestions(
                [
                  {
                    question_id: "body_diagram_additional_comments",
                    structure: {
                      question: "Additional Comments:",
                      type: "textarea",
                      required: false,
                    },
                  },
                ],
                form,
                null,
                note === true ? true : false
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <ColorRing
                  visible={progress}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#2164ec",
                    "#2164ec",
                    "#2164ec",
                    "#2164ec",
                    "#2164ec",
                  ]}
                />
                <button // changed from input
                  type="submit"
                  className="btn-submit-section"
                  style={{ alignSelf: "center" }}
                  // onClick={handleNextSection}
                >
                  Save and Continue
                </button>
              </div>
            </form>
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  );
}
