import React from "react";

import "./question.css";

export default function Box({
  register,
  registerName,
  data,
  className,
  questionId,
  type,
  number,
  uniform,
  inline,
  errorData,
}) {
  const below = data.below === true ? true : false;
  // console.log("register: " + register);
  switch (type) {
    case "textarea":
      return (
        <div
          className={"question " + className}
          style={{
            alignItems: "center",
            fontSize: 18,
          }}
        >
          <label>
            {uniform === false
              ? `${number}. ${data.question}`
              : `${data.question}`}
            {data.required === true && <span className="error-text">*</span>}
          </label>
          <div>
            <textarea
              className={`input-box ${errorData && "error-input"}`}
              style={{
                width: "40%",
                height: "90px",
                display: "block",
                marginTop: "5px",
              }}
              {...register(registerName.toLowerCase().split(" ").join("_"), {
                required: data.required,
              })}
              aria-invalid={errorData ? "true" : "false"}
            />
            {errorData && errorData?.type === "required" && (
              <span className="error-text">
                Please complete the required field.
              </span>
            )}
          </div>
        </div>
      );
    case "text":
    case "number":
    case "date":
    case "tel":
      return inline === true ? (
        <div
          className={"question " + className}
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr 6fr",
            width: "100%",
            alignItems: "center",
            fontSize: 18,
          }}
        >
          <div style={{ gridColumn: "1" }}>
            {!uniform && <label>{`${number}. `}</label>}
            <label>
              {`${data.question}`}
              {data.required === true && <span className="error-text">*</span>}
            </label>
          </div>
          <div style={{ gridColumn: "3" }}>
            <input
              className={`input-box ${errorData && "error-input"}`}
              type={type}
              placeholder={type === "date" ? "MM-DD-YYYY" : data.question}
              {...register(registerName.toLowerCase().split(" ").join("_"), {
                required: data.required,
              })}
              aria-invalid={errorData ? "true" : "false"}
            />
            {errorData && errorData?.type === "required" && (
              <span className="error-text">
                Please complete the required field.
              </span>
            )}
          </div>
        </div>
      ) : (
        <div
          className={"question " + className}
          style={{
            fontSize: 18,
          }}
        >
          <div>
            {!uniform && <label>{`${number}. `}</label>}
            {!below && (
              <label>
                {`${data.question}`}
                {data.required === true && (
                  <span className="error-text">*</span>
                )}
              </label>
            )}
          </div>
          <div>
            <input
              className={`input-box ${errorData && "error-input"}`}
              type={type}
              style={{ marginTop: "5px" }}
              placeholder={type === "date" ? "MM-DD-YYYY" : data.question}
              {...register(registerName.toLowerCase().split(" ").join("_"), {
                required: data.required,
              })}
              aria-invalid={errorData ? "true" : "false"}
            />
            {below && (
              <label style={{ marginLeft: "12px" }}>
                {`${data.question}`}
                {data.required === true && (
                  <span className="error-text">*</span>
                )}
              </label>
            )}
          </div>
          {errorData && errorData?.type === "required" && (
            <span className="error-text">
              Please complete the required field.
            </span>
          )}
        </div>
      );
    default:
      console.warn("Invalid 'Box' type: " + type);
      return <p>Invalid "Box" type: {type}</p>;
  }
}
