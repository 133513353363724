import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import { AccordionSummary as MuiAccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai/";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import { renderQuestions } from "../RenderQuestions";
import { useAccordion } from "./AccordionProvider";

import { useAuthentication } from "../../auth";

import { getNestedValue } from "../../utils/Utils.js";

export default function PhysicalExamAccordion({
  title,
  questions,
  defaultValues,
  accordionid,
  sendToURL,
  onFailExport,
  note,
  setCompletion,
  bodyPart,
}) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();

  // modal for unanswered questions
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const questionNumberMapping = useRef({});

  // Get props from AccordionProvider
  const { activeAccordion, setActiveAccordion, styles, incrementAccordion } =
    useAccordion();
  const expanded = activeAccordion === accordionid;

  // Custom AccordionSummary styling
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        expanded === true ? (
          <Remove sx={{ color: "white" }} />
        ) : (
          <Add sx={{ color: "white" }} />
        )
      }
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginRight: theme.spacing(3),
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      marginRight: theme.spacing(3),
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
    },
  }));

  // Create form
  const form = useForm({
    shouldUnregister: true,
    defaultValues: defaultValues || {},
    mode: "all",
  });

  const {
    handleSubmit,
    watch,
    getValues,
    reset,
    formState,
    trigger,
    clearErrors,
  } = form;

  const { errors, isValid, isDirty, isSubmitSuccessful } = formState;
  // Completion status of form

  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    setCompleted(isValid);
  }, [isValid]);

  // Set global form completion state
  useEffect(() => {
    setCompletion((prev) => {
      return { ...prev, [bodyPart]: completed };
    });
  }, [completed]);

  const openUnansweredQuestionsToast = (unansweredQuestions) => {
    // Check if there are unanswered questions
    if (unansweredQuestions.length > 0) {
      const errorMessage = (
        <div>
          <p>The following questions have not been answered:</p>
          <ul>
            {unansweredQuestions.map((question) => (
              <li key={question.registerNameCopy}>
                {question.questionNumber} - {question.questionName}
              </li>
            ))}
          </ul>
        </div>
      );
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 10000, // Will not auto-close
        closeButton: true, // Assuming you want a close button
      });
    } else {
      console.log("No unanswered questions, not showing toast.");
    }
  };

  const openUnansweredQuestionsModal = async () => {
    const isValid = await trigger();
    if (!isValid) {
      // Check if errors object is empty
      if (!errors || Object.keys(errors).length === 0) {
        // console.log("There are no ERRORS");
        return;
      }

      const newUnansweredQuestions = [];
      for (const [
        registerNameCopy,
        [questionName, questionNumber],
      ] of Object.entries(questionNumberMapping.current)) {
        if (
          questionName &&
          getNestedValue(errors, registerNameCopy) !== undefined
        ) {
          newUnansweredQuestions.push({
            registerNameCopy,
            questionName,
            questionNumber,
          });
        }
      }

      newUnansweredQuestions.sort(
        (a, b) => a.questionNumber - b.questionNumber
      );

      setUnansweredQuestions(newUnansweredQuestions);

      if (newUnansweredQuestions.length > 0) {
        openUnansweredQuestionsToast(newUnansweredQuestions);
        // console.log("OPEN TOAST!");
      }
      // else {
      //   console.log("No unanswered questions, not opening modal.");
      // }
    }
  };

  // Indicates that the form was checked for prior completion
  const [loading, setLoading] = useState(true);
  const PROGRESS_SIZE = 30;
  const clearErrorsTimer = useRef(null);

  useEffect(() => {
    if (Array.isArray(questions) && questions.length > 0) setLoading(false);
  }, [questions]);

  // Check if form is already filled. If so, mark as complete
  useEffect(() => {
    if (loading === false) {
      const checkAlreadyCompleted = async () => {
        await form.trigger();

        if (isValid === true && Object.keys(errors).length === 0) {
          setCompleted(true);
        } else {
          setCompleted(false);
        }
      };

      if (Array.isArray(questions) && questions.length > 0)
        checkAlreadyCompleted();
      /*
      Clear errors after calling trigger. 
      Need a slight delay before clearing errors 
      or it'll execute too early and not actually clear.
    */
      clearTimeout(clearErrorsTimer.current);
      clearErrorsTimer.current = setTimeout(() => {
        if (clearErrors) clearErrors();
      }, 1000);
    }
  }, [loading]);

  // Contains current state of form during submission. Updates upon submission.
  const [submittedData, setSubmittedData] = useState(defaultValues);

  useEffect(() => {
    if (isSubmitSuccessful) {
      /* 
        Set current form values as default values.
        We will use this to detect if the form state is dirty 
        for the sake of marking form completion. If dirty, set form completion to false.
        Dirty in this case means at least one of the fields does not match its default value.
      */
      reset(getValues());
    }
  }, [submittedData]);

  const onSubmit = async (data) => {
    try {
      // check if form errors are present. If so, DO NOT COLLAPSE/CHANGE ACCORDION
      const isValid = await trigger();
      if (isValid) {
        if (isAuthenticated) {
          setLoading(true);
          const formData = {
            [bodyPart]: data,
          };
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "POST",
            url: sendToURL,
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            data: formData,
          };
          await axios.request(options);
          setLoading(false);
          setSubmittedData(data);
          setCompleted(true);
          incrementAccordion();

          toast.success("Form values submitted successfully!", {
            toastId: 1,
            position: "top-center",
            autoClose: 3000, // milliseconds
          });
        }
      } else {
        // Show modal with unanswered questions
        openUnansweredQuestionsModal();
      }
    } catch (error) {
      toast.error(`Submission Error: ${error.message}`, {
        toastId: 1,
        position: "top-center",
        autoClose: 3000,
      });

      setLoading(false);
      if (onFailExport)
        // Run given function on failure of submission
        onFailExport(error);
      console.error("Error submitting form values:", error);
    }
  };

  const ref = useRef(null);

  // Scrolls to top of Accordion after expansion
  const executeScroll = () => {
    if (ref && ref.current && activeAccordion === accordionid)
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => executeScroll(), 1000);
  }, [activeAccordion]);

  return (
    <div className={` ${showErrorModal ? "modal-opened" : ""}`}>
      <Accordion
        expanded={expanded}
        onChange={() =>
          setActiveAccordion(activeAccordion !== accordionid ? accordionid : 0)
        }
        ref={ref}
      >
        <AccordionSummary sx={styles.accordionHeader}>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: "Bold",
              color: "white",
            }}
          >
            {title}
          </Typography>
          {loading ? (
            <ColorRing
              visible={true}
              height={PROGRESS_SIZE}
              width={PROGRESS_SIZE}
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"]}
            />
          ) : completed && !isDirty ? (
            <AiFillCheckCircle
              style={{
                color: "#059e0a",
                width: PROGRESS_SIZE,
                height: PROGRESS_SIZE,
                backgroundColor: "white",
                borderRadius: "100%",
              }}
            />
          ) : (
            <AiFillExclamationCircle
              style={{
                color: "#b31414",
                width: PROGRESS_SIZE,
                height: PROGRESS_SIZE,
                backgroundColor: "white",
                borderRadius: "100%",
              }}
            />
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <form autoComplete="one-time-code" onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="container-questions">
                {renderQuestions(
                  questions,
                  form,
                  null,
                  note === true ? true : false,
                  undefined,
                  undefined,
                  questionNumberMapping
                )}
              </div>
              <button
                type="submit"
                className="btn-submit-section"
                onClick={openUnansweredQuestionsModal}
              >
                Save and Continue
              </button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>

      {/* Modal for showwing unanswered questions */}
      {showErrorModal && (
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">You have UNANSWERED questions</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  // destroy.current = true;
                  // caseIDRef.current.value = "";
                  setShowErrorModal(false);
                  // Need a slight delay before refocusing, or it'll execute too early
                  // setTimeout(() => {
                  //   if (caseIDRef.current) {
                  //     caseIDRef.current.focus();
                  //   }
                  // }, 0);
                }}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text">
                The following questions have not been aswered, please fill them
                out before continuing:
              </p>
              <ul>
                {unansweredQuestions.map((question) => (
                  <li key={question.question}>{question.question}</li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" onClick={() => setShowCreateModal(false)}>
                Cancel
              </button> */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // destroy.current = false;
                  setShowErrorModal(false);
                }}
              >
                Fill them out
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
