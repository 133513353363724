import React from "react";
import BoxArray from "./questions/BoxArray";
import Box from "./questions/Box";
import Radio from "./questions/Radio";
import Conditional from "./questions/Conditional";
import Group from "./questions/Group";
import TableForm from "./questions/TableForm";
import Note from "./questions/Note";
import Scale from "./questions/Scale";
import { getNestedValue } from "../utils/Utils";

/*
    Recursively compute number of questions within a 'group' type
    for the sake of numbering questions
  */
function getGroupLength(groupData) {
  let total = 0;

  for (let i = 0; i < groupData.structure.question.length; i++) {
    if (groupData.structure.question[i].structure.type !== "group") total++;
    /*
      This covers the edge case of a uniform group nested within another group.
      We want to prevent recursion in this case because ALL questions within
      a uniform group SHARE the same NUMBER.
    */ else if (
      groupData.structure.question[i].structure.type === "group" &&
      groupData.structure.question[i].structure.uniform === true
    )
      total++;
    else total += getGroupLength(groupData.structure.question[i]);
  }

  return total;
}

// render all questions
function renderQuestions(
  questionList,
  form,
  registerName = null,
  note = false,
  number = null,
  uniform = null,
  questionNumberMapping
) {
  const {
    register,
    unregister,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  // console.log("questionNumberMapping in renderQuestion: ", questionNumberMapping)
  // console.log("questionList in renderQuestion: ", questionList)



  // const watchAllFields = watch();

  // console.log(JSON.stringify(watchAllFields, null, 2));
  /* 
          For numbering questions. If number is defined, that means
          it was passed as a parameter and is being used to number questions
          within a group. This is needed since questions within a group are called
          recursively, so we want to prevent the numbering from resetting to 1.
        */
  if (!Number.isInteger(number)) number = 1;

  /*
          Used for groups. If uniform is true, then all questions within a group
          share the same color and number.
        */
  uniform = uniform === true ? true : false;

  return (
    questionList !== undefined &&
    questionList.map((data) => {
      const questionId = data.question_id;
      const type = data.structure.type;
      const inline = data.structure.inline === true ? true : false;
      // const questionName = data.structure.question ? data.structure.question : data.structure.title;
      const questionName = typeof data.structure.question === 'object' ? data.structure.question.propertyName : data.structure.question || data.structure.title;


      const registerNameCopy =
        registerName !== null
          ? `${registerName}.${questionId}`
          : `${questionId}`;

      // Implements alternating row colors per question
      const className = number % 2 === 0 ? "question-odd" : "question-even";

      const errorDataNote = getNestedValue(errors, `${registerNameCopy}.note`);

      switch (type) {
        case "text":
        case "textarea":
        case "number":
        case "date":
        case "tel": {
          const errorDataAnswer = getNestedValue(
            errors,
            `${registerNameCopy}.answer`
          );

          const currentNumber = uniform === true ? number : number++;

          // console.log("HRE", currentNumber)

          // Update the ref's current property
          if (questionNumberMapping && questionNumberMapping.current) {
            questionNumberMapping.current[registerNameCopy] = [questionName, currentNumber];
          }

          // console.log("HRE222222", currentNumber)

          // console.log(errors);
          return (
            <React.Fragment key={registerNameCopy}>
              <Box
                questionId={questionId}
                data={data.structure}
                register={register}
                registerName={`${registerNameCopy}.answer`}
                inline={inline}
                type={type}
                className={className}
                uniform={uniform}
                // share same color and number if uniform === true
                number={currentNumber}
                errorData={errorDataAnswer}
              />
              {note === true && uniform === false && (
                <Note
                  register={register}
                  registerName={`${registerNameCopy}.note`}
                  unregister={unregister}
                  className={className}
                  getValues={getValues}
                  control={control}
                  errorData={errorDataNote}
                />
              )}
            </React.Fragment>
          );
        }
        case "radio":
        case "checkbox": {
          const errorDataAnswer = getNestedValue(
            errors,
            `${registerNameCopy}.answer`
          );

          const currentNumber = uniform === true ? number : number++;

          // Update the ref's current property
          if (questionNumberMapping && questionNumberMapping.current) {
            questionNumberMapping.current[registerNameCopy] = [questionName, currentNumber];
          }

          return (
            <React.Fragment key={registerNameCopy}>
              <Radio
                questionId={questionId}
                data={data.structure}
                register={register}
                registerName={`${registerNameCopy}.answer`}
                inline={inline}
                className={className}
                uniform={uniform}
                number={currentNumber}
                getValues={getValues}
                errorData={errorDataAnswer}
              />
              {note === true && uniform === false && (
                <Note
                  register={register}
                  registerName={`${registerNameCopy}.note`}
                  unregister={unregister}
                  className={className}
                  getValues={getValues}
                  control={control}
                  errorData={errorDataNote}
                />
              )}
            </React.Fragment>
          );
        }
        case "scale": {
          const errorDataAnswer = getNestedValue(
            errors,
            `${registerNameCopy}.answer`
          );

          const currentNumber = uniform === true ? number : number++;

          // Update the ref's current property
          if (questionNumberMapping && questionNumberMapping.current) {
            questionNumberMapping.current[registerNameCopy] = [questionName, currentNumber];
          }

          return (
            <React.Fragment key={registerNameCopy}>
              <Scale
                questionId={questionId}
                data={data.structure}
                register={register}
                registerName={`${registerNameCopy}.answer`}
                className={className}
                uniform={uniform}
                number={currentNumber}
                errorData={errorDataAnswer}
              />
              {note === true && uniform === false && (
                <Note
                  register={register}
                  registerName={`${registerNameCopy}.note`}
                  unregister={unregister}
                  className={className}
                  getValues={getValues}
                  control={control}
                  errorData={errorDataNote}
                />
              )}
            </React.Fragment>
          );
        }
        case "box_array": {
          const errorDataAnswer = getNestedValue(
            errors,
            `${registerNameCopy}.answer`
          );

          const currentNumber = uniform === true ? number : number++;

          // Update the ref's current property
          if (questionNumberMapping && questionNumberMapping.current) {
            questionNumberMapping.current[registerNameCopy] = [questionName, currentNumber];
          }

          return (
            <React.Fragment key={registerNameCopy}>
              <BoxArray
                questionId={questionId}
                data={data.structure}
                register={register}
                registerName={`${registerNameCopy}.answer`}
                control={control}
                className={className}
                number={currentNumber}
                errorData={errorDataAnswer}
              />
              {note === true && uniform === false && (
                <Note
                  register={register}
                  registerName={`${registerNameCopy}.note`}
                  unregister={unregister}
                  className={className}
                  getValues={getValues}
                  control={control}
                  errorData={errorDataNote}
                />
              )}
            </React.Fragment>
          );
        }
        case "group": {
          const numberCopy = number;
          /*
          Using data.structure.uniform here. That and just "uniform" are different.
          This refers to the uniform property of the current question we are rendering.
          "Uniform" refers to the function parameter passed by the parent question component (e.g. another group).
        */
          number =
            data.structure.uniform === true || uniform === true
              ? number + 1
              : number + getGroupLength(data);

          const errorDataAnswer = getNestedValue(
            errors,
            `${registerNameCopy}.answer`
          );

          //const currentNumber = uniform === true ? number : number++;

          // Update the ref's current property
          if (questionNumberMapping && questionNumberMapping.current) {
            questionNumberMapping.current[registerNameCopy] = [questionName, numberCopy];
          }

          return (
            <React.Fragment key={registerNameCopy}>
              <Group
                form={form}
                questionId={questionId}
                data={data.structure}
                register={register}
                registerName={registerNameCopy}
                renderQuestions={renderQuestions}
                className={className}
                number={numberCopy}
                note={note}
                errorData={errorDataAnswer}
                questionNumberMapping={questionNumberMapping}
              />
              {/* 
                "data.structure.uniform" refers to the property within the Group component itself.
                "uniform" refers to the parameter passed by its parent (i.e. Group nested in a Group).
            */}
              {note === true &&
                data.structure.uniform === true &&
                uniform === false && (
                  <Note
                    register={register}
                    registerName={`${registerNameCopy}.note`}
                    unregister={unregister}
                    className={className}
                    getValues={getValues}
                    control={control}
                    errorData={errorDataNote}
                  />
                )}
            </React.Fragment>
          );
        }
        case "conditional": {
          const errorDataAnswer = getNestedValue(
            errors,
            `${registerNameCopy}.answer`
          );

          const currentNumber = uniform === true ? number : number++;

          // Update the ref's current property
          if (questionNumberMapping && questionNumberMapping.current) {
            questionNumberMapping.current[registerNameCopy] = [questionName, currentNumber];
          }

          return (
            <React.Fragment key={registerNameCopy}>
              <Conditional
                form={form}
                questionId={questionId}
                data={data.structure}
                register={register}
                registerName={registerNameCopy}
                renderQuestions={renderQuestions}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                inline={inline}
                className={className}
                uniform={uniform}
                number={currentNumber}
                note={note}
                control={control}
                errorData={errorDataAnswer}
              />
              {note === true && uniform === false && (
                <Note
                  register={register}
                  registerName={`${registerNameCopy}.note`}
                  unregister={unregister}
                  className={className}
                  getValues={getValues}
                  control={control}
                  errorData={errorDataNote}
                />
              )}
            </React.Fragment>
          );
        }
        case "table": {
          const errorDataAnswer = getNestedValue(
            errors,
            `${registerNameCopy}.answer`
          );

          const currentNumber = uniform === true ? number : number++;

          // Update the ref's current property
          if (questionNumberMapping && questionNumberMapping.current) {
            questionNumberMapping.current[registerNameCopy] = [questionName, currentNumber];
          }

          return (
            <React.Fragment key={registerNameCopy}>
              <TableForm
                questionId={questionId}
                data={data.structure}
                register={register}
                setValue={setValue}
                registerName={`${registerNameCopy}.answer`}
                className={className}
                uniform={uniform}
                number={currentNumber}
                errorData={errorDataAnswer}
              />
              {note === true && uniform === false && (
                <Note
                  register={register}
                  registerName={`${registerNameCopy}.note`}
                  unregister={unregister}
                  className={className}
                  getValues={getValues}
                  control={control}
                  errorData={errorDataNote}
                />
              )}
            </React.Fragment>
          );
        }
        default:
          return <p>not valid. type: {type}</p>;
      }
    })
  );
}

export { renderQuestions };
