import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import PatientPreScreenSubmissionsPage from "./PatientPreScreenSubmissionsPage";
import { useLocation } from "react-router-dom";
import { useAuthentication } from "../auth";

export default function PatientPreScreenSubmissionsPageDataHandler() {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();

  const [patientData, setPatientData] = useState(null);
  const { id } = useParams();

  const location = useLocation();
  const currentRoute = location.pathname;

  // Function to extract "doctor" from the route
  const extractRoleFromRoute = (route) => {
    const parts = route.split("/");
    // console.log("PARTS", parts);
    return parts.length >= 3 ? parts[1] : null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-historian-doctor-patient-pre-screen-answers/${id}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const response = await Axios.request(options);
          setPatientData(response.data);
          const roleFromRoute = extractRoleFromRoute(currentRoute);
        }

        // const roleFromRoute = extractRoleFromRoute(currentRoute);
        // console.log("Fetched data from patient-pre-screen-answers:", response.data);
        // console.log("Current Route:", currentRoute);
        // console.log("Doctor from Route:", roleFromRoute);
      } catch (error) {
        console.error("Failed to fetch patient data: ", error);
      }
    };

    fetchData();
  }, [id, currentRoute]);

  return patientData ? (
    <PatientPreScreenSubmissionsPage
      patientData={patientData}
      roleFromRoute={extractRoleFromRoute(currentRoute)}
    />
  ) : (
    <div>Loading...</div>
  );
}
