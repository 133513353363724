import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./PatientForm.css";
import "./HistorianPatientFormResponses.css";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AccordionProvider } from "../components/Accordion/AccordionProvider";
import FormAccordion from "../components/Accordion/FormAccordion";
import DrawingAccordion from "../components/Accordion/DrawingAccordion";

// import sample from "../SampleQuestions";
import BodyPartsAccordion from "../components/Accordion/BodyPartsAccordion";
import HistorianReviewerAccordion from "../components/Accordion/HistorianReviewerAccordion";
import { useAuthentication } from "../auth";
// 50.18.33.65

export default function HistorianPatientResponses({ patientData }) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [questions, setQuestions] = useState({});
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-patient-questions`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          const response = await axios.request(options);
          setQuestions(response.data[0].questions);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchQuestions();
  }, []);

  // Tracks state of all forms within provider
  const [completion, setCompletion] = useState(() => {
    const initial = {};
    for (let i = 0; i < 9; i++) {
      initial[`accordion-${i + 1}`] = false;
    }
    return initial;
  });

  // Checks completion status of all forms
  const checkAllCompleted = () => {
    return Object.values(completion).every((item) => item === true);
  };

  const errorMessageTimer = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const ERROR_TIME = 5 * 1000;

  const startErrorTimer = () => {
    // Clear previous timer
    clearTimeout(errorMessageTimer.current);
    setErrorMessage(true);

    errorMessageTimer.current = setTimeout(() => {
      // Display warning
      setErrorMessage(false);
    }, ERROR_TIME);
  };

  return (
    <div className="patient-form-container">
      <h1 className="historian-patient-form-responses-case-id-text">
        Case ID: {id}
      </h1>
      <AccordionProvider>
        {/* #1 Preliminary */}
        <FormAccordion
          title={"i. Preliminary"}
          questions={questions.preliminary}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #2 Job Description */}
        <FormAccordion
          title={"ii. Job Description"}
          questions={questions.job_description}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #3 History of Injury */}
        <FormAccordion
          title={"iii. History of Injury"}
          questions={questions.history_of_injury}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #4 Past Medical and Family History */}
        <FormAccordion
          title={"iv. Past Medical and Family History"}
          questions={questions.past_medical_and_family_history}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #5 Social History */}
        <FormAccordion
          title={"v. Social History"}
          questions={questions.social_history}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #6 Subjective Pain (Body Diagram) */}
        <DrawingAccordion
          title={"vi. Subjective Pain"}
          id={id}
          isImage={true}
          setCompletion={setCompletion}
          sendToFormURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          defaultValues={patientData}
          note={true}
        />
        {/* #7 Activities of Daily Living */}
        <FormAccordion
          title={"vii. Activities of Daily Living"}
          questions={questions.adl}
          defaultValues={patientData}
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-answers/${id}`}
          note={true}
          setCompletion={setCompletion}
        />
        {/* #8 Body Parts Selection */}
        <BodyPartsAccordion
          title="viii. Body Parts"
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/bodyparts/${id}`}
          fetchFromURL={`${process.env.REACT_APP_BACKEND_URL}/get-body-parts/${id}`}
          setCompletion={setCompletion}
        />
        {/* #9 Historian Reviewed By */}
        <HistorianReviewerAccordion
          title="ix. Historian Reviewed By"
          sendToURL={`${process.env.REACT_APP_BACKEND_URL}/historian-doctor-patient-pre-screen-answers/${id}`}
          setCompletion={setCompletion}
        />
      </AccordionProvider>
      <div style={{ alignSelf: "flex-end" }}>
        {errorMessage && (
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              border: "solid 1px black",
              display: "inline",
            }}
          >
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Please complete all forms before submitting{" "}
              <span className="error-text">&#9888;</span>
            </span>
          </div>
        )}
        <button
          type="submit"
          className="btn-submit-section"
          onClick={async () => {
            if (checkAllCompleted() === true) {
              // Update the historian_completed field to true
              const updatedData = {
                historian_completed: true,
              };
              // Make a POST request to update the document with the new field (historian_completed: true)
              try {
                if (isAuthenticated) {
                  let accessToken;
                  try {
                    accessToken = await getAccessTokenSilently();
                  } catch (error) {
                    console.error("Error fetching access token:", error);
                  }
                  const options = {
                    method: "POST",
                    url: `${process.env.REACT_APP_BACKEND_URL}/historian-doctor-patient-pre-screen-answers/${id}`,
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                      "Content-Type": "application/json",
                    },
                    data: updatedData,
                  };
                  const response = await axios.request(options);
                  navigate("/historian-patient-forms");
                }
              } catch (error) {
                console.error("Error updating historian_completed:", error);
              }

              toast.success("Form submitted successfully!", {
                toastId: 1,
                position: "top-center",
                autoClose: 3000, // milliseconds
              });
            } else startErrorTimer();
          }}
        >
          Submit Form
        </button>
      </div>
    </div>
  );
}
