import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import "./DoctorTemplate.css";

import PdfViewer from "../components/PdfViewer";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button as MuiButton, Modal, Box, Typography } from "@mui/material";

import { useAuthentication } from "../auth";

function DoctorTemplate() {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  let { id } = useParams();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleActionOptionClick = () => {
    handleOpenModal();
  };

  const [accessTokenHeader, setAccessTokenHeader] = useState(null);

  useEffect(() => {
    const getAccessTokenHeader = async () => {
      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }

        setAccessTokenHeader({ Authorization: `Bearer ${accessToken}` });
      }
    };
    getAccessTokenHeader();
  }, [isAuthenticated]);

  const downloadDocx = async () => {
    if (isAuthenticated) {
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently();
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
      const options = {
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/get-template-docx/${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      };
      await axios.request(options).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    } else {
      console.error("User is not authenticated. Cannot make the request.");
    }
  };

  const handleFinishAll = async () => {
    // Logic for finish-all action...
    const updatedData = {
      doctor_completed: true,
    };

    if (isAuthenticated) {
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently();
      } catch (error) {
        console.error("Error fetching access token:", error);
        throw new Error("Failed to fetch access token");
      }

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/historian-doctor-patient-pre-screen-answers/${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: updatedData,
      };

      await axios
        .request(options)
        .then((response) => {
          toast.success("Marked as Complete!", {
            toastId: 1,
            position: "top-center",
            autoClose: 3000,
          });
          navigate("/doctor-patient-forms");
        })
        .catch((error) => {
          toast.error(`Submission Error: ${error.message}`, {
            toastId: 1,
            position: "top-center",
            autoClose: 3000,
          });
          console.error("Error updating doctor_completed:", error);
        });
    } else {
      console.error("User is not authenticated. Cannot make the request.");
    }
  };

  return (
    <div className="doctor-template-container">
      <div className="doctor-template-back-button-container">
        <Button
          onClick={handleGoBack}
          variant="primary"
          className="doctor-template-back-button"
        >
          back
        </Button>
      </div>
      <div className="doctor-template-info-actions-container">
        <div className="doctor-template-gen-temp">
          <h1 className="doctor-template-gen-temp-text">Generated Template</h1>
        </div>
        <div className="actions-container">
          <Button
            className="doctor-template-download-doc-button"
            onClick={downloadDocx}
          >
            Download .docx
          </Button>
          <Button
            className="doctor-template-finish-button"
            onClick={handleActionOptionClick}
          >
            Mark as Complete
          </Button>
        </div>
      </div>
      <div className="doctor-template-generated-template-container">
        <PdfViewer
          url={{
            url: `${process.env.REACT_APP_BACKEND_URL}/get-template-pdf/${id}`,
            httpHeaders: accessTokenHeader,
            withCredentials: true,
          }}
        />
      </div>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            minWidth: 550,
            maxHeight: "90vh",
            overflowY: "auto",
            textAlign: "center", // Center the text
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            fontWeight="bold"
            sx={{ marginBottom: 6 }}
          >
            Are you sure you want to mark as complete?
          </Typography>
          {/* <Typography variant="body1" gutterBottom sx={{ marginBottom: 6 }}>
            Are you sure you want to submit?
          </Typography> */}
          <Box sx={{ marginY: 2 }}>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={handleCloseModal}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </MuiButton>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleCloseModal();
                handleFinishAll();
              }}
            >
              Confirm
            </MuiButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default DoctorTemplate;
