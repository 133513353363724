import React from "react";

export default function Group(props) {
  const {
    data,
    form,
    questionId,
    registerName,
    renderQuestions,
    number,
    className,
    note,
    questionNumberMapping
  } = props;
  const uniform = props.uniform === true ? true : false;
  const questionNum = data.question.length;
  const title = data.title === true ? true : false;
  const above = data.above === true ? true : false;
  const inline = data.inline === true ? true : false;
  const innerUniform = data.uniform === true ? true : false;
  const name = data.name !== undefined ? data.name : questionId;

  // console.log("questionNumberMapping in group: ", questionNumberMapping)
  // inline: true,
  // uniform: true,
  // above: true,
  return (
    <div>
      {title === true && (
        <h3
          style={{
            margin: 0,
            padding: 5,
            paddingLeft: 10,
            backgroundColor: "#5b83d1",
            // #6d92dd
            color: "white",
          }}
        >
          {name}
        </h3>
      )}
      {above === true && (
        <p className={"question " + className} style={{ fontSize: "18px" }}>
          {uniform === false ? `${number}. ${name}` : `${name}`}
        </p>
      )}
      <div>
        <div
          className={inline === true ? className : ""}
          style={
            inline === true && above === false
              ? {
                  display: "grid",
                  gridTemplateColumns: "2fr 8fr",
                  width: "100%",
                  alignItems: "center",
                }
              : {
                  width: "100%",
                }
          }
        >
          {inline === true && above === false && (
            <p
              className={"question " + className}
              style={{ fontSize: "18px" }}
            >{`${number}. ${name}`}</p>
          )}
          <div
            style={
              inline === true
                ? {
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: `repeat(${questionNum}, 1fr)`,
                    columnGap: "30px",
                  }
                : {
                    width: "100%",
                  }
            }
          >
            {renderQuestions(
              data.question,
              form,
              registerName,
              note,
              number,
              innerUniform,
              questionNumberMapping
            )}
          </div>
        </div>
      </div>
      {title === true && (
        <div
          style={{ width: "100%", padding: 4, backgroundColor: "#000000" }}
        />
      )}
    </div>
  );
}
