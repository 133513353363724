import React from "react";
import "./PatientSetup.css";
import { Button } from "react-bootstrap";
import Axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import IdleTimer from "../utils/IdleTimer";

function PatientSetup() {
  const [caseID, setCaseID] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});

  const navigate = useNavigate();

  /* 
    Determines whether the session should be destroyed or not upon leaving the page.
    The default behaviour upon leaving the page should destroy, but we need to make
    an exception when trying to navigate to '/patient-form/' or 'patient-prescreen'
    because otherwise it would cause an infinite pin validation loop.
  */
  const destroy = useRef(true);
  const shouldDestroy = () => {
    return destroy.current;
  };

  useEffect(() => {
    if (caseID) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [caseID]);

  const checkPatientExists = async () => {
    try {
      // Check if the case_id exists in the historian-answers collection
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get-patient-pre-screen-answers/${caseID}`,
        { withCredentials: true }
      );
      const statusCode = response.status;

      if (statusCode === 200) {
        const patientData = response.data; // Assuming response.data contains patient information
        // console.log("PATIENT DATA: ", patientData);
        setPatientInfo(patientData);

        if (
          patientData?.patient_completed === true ||
          patientData?.historian_completed === true ||
          patientData?.doctor_completed === true
        ) {
          setShowRejectModal(true);
        } else {
          setShowViewModal(true);
        }
      }
    } catch (error) {
      const statusCode = error.response.status;
      if (statusCode === 401) navigate(`/patient-pin`, { replace: true });
      else if (statusCode === 404) setShowCreateModal(true);
      console.error("Error checking patient in patients collection:", error);
    }
  };

  // Move to prescreen page to create patient
  const handleCreatePatient = async () => {
    setShowCreateModal(false);
    navigate(`/patient-prescreen/${caseID}`, { replace: true });
  };

  //
  const handleViewPatient = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/set-form-id`,
        {
          case_id: caseID,
        },
        { withCredentials: true }
      );
      if (response.status === 200) {
        setShowViewModal(false);
        navigate(`/patient-form/${caseID}`, { replace: true });
      }
    } catch (error) {
      console.error("Error: ", error);
      if (error.response?.status === 401)
        navigate("/patient-pin", { replace: true });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (buttonDisabled) {
      // Handle case when the button is disabled
      return;
    } else {
      // Check if the patient exists
      checkPatientExists();
    }
  };

  const caseIDRef = useRef(null);

  return (
    <div
      className={`patient-setup-container ${
        showCreateModal || showViewModal || showRejectModal
          ? "modal-opened"
          : ""
      }`}
    >
      <IdleTimer shouldDestroy={shouldDestroy} />
      <div className="patient-setup-small-rectangle">Patient Setup</div>
      <form className="patient-setup-big-rectangle" onSubmit={handleSubmit}>
        <div className="patient-setup-textbox-container">
          <div className="patient-setup-label">Case ID</div>
          <input
            ref={caseIDRef}
            type="number"
            autoFocus={true}
            className="patient-setup-textbox"
            onChange={(event) => {
              // console.log(event.target.value);
              setCaseID(event.target.value);
            }}
            disabled={showCreateModal || showViewModal || showRejectModal}
          />
        </div>
        <Button
          className="patient-setup-next-button"
          variant="primary"
          size="lg"
          // Doesn't allow button to be pressed via 'enter' key when modals pop up
          disabled={
            showCreateModal ||
            showViewModal ||
            showRejectModal ||
            buttonDisabled
          }
          type="submit"
        >
          Next
        </Button>
      </form>

      {/* Modal for: Creating a new patient */}
      {showCreateModal && (
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create New Patient</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  destroy.current = true;
                  caseIDRef.current.value = "";
                  setShowCreateModal(false);
                  // Need a slight delay before refocusing, or it'll execute too early
                  setTimeout(() => {
                    if (caseIDRef.current) {
                      caseIDRef.current.focus();
                    }
                  }, 0);
                }}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text">
                No existing patient has this case ID. Do you wish to create a
                new patient?
              </p>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" onClick={() => setShowCreateModal(false)}>
                Cancel
              </button> */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  destroy.current = false;
                  handleCreatePatient();
                }}
              >
                Yes, Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for: Patient exists but has not finished their form */}
      {showViewModal && (
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Patient</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  destroy.current = true;
                  caseIDRef.current.value = "";
                  setShowViewModal(false);
                  // Need a slight delay before refocusing, or it'll execute too early
                  setTimeout(() => {
                    if (caseIDRef.current) {
                      caseIDRef.current.focus();
                    }
                  }, 0);
                }}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text-instructions">
                You are about to view the following patient:
              </p>
              <p className="modal-text">
                <span className="patient-setup-modal-label">Case ID:</span>{" "}
                {patientInfo.case_id.answer}
              </p>
              <p className="modal-text">
                <span className="patient-setup-modal-label">First Name:</span>{" "}
                {patientInfo.full_name.first.answer}
              </p>
              <p className="modal-text">
                <span className="patient-setup-modal-label">Last Name:</span>{" "}
                {patientInfo.full_name.last.answer}
              </p>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" onClick={() => setShowViewModal(false)}>
                Cancel
              </button> */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  destroy.current = false;
                  handleViewPatient();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for: Patient already submitted */}
      {showRejectModal && (
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Patient Already Submitted</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  caseIDRef.current.value = "";
                  setShowRejectModal(false);
                  // Need a slight delay before refocusing, or it'll execute too early
                  setTimeout(() => {
                    if (caseIDRef.current) {
                      caseIDRef.current.focus();
                    }
                  }, 0);
                }}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-text">
                This patient has already submitted their form. Please view their
                answers from the Historian/Doctor's view.
              </p>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" onClick={() => setShowViewModal(false)}>
                Cancel
              </button> */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowRejectModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PatientSetup;
