import React, { useEffect, useState } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useAuthentication } from "./auth";

const RouteGuard = ({ accessLevel, children }) => {
  const location = useLocation();
  const {
    user,
    // userRoles,
    loginWithRedirect,
    getIdTokenClaims,
    isAuthenticated,
  } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);

  const [userRoles, setUserRoles] = useState(null);
  useEffect(() => {
    if (isAuthenticated === true)
      getIdTokenClaims().then((claims) => {
        const rolesClaim =
          claims[`${process.env.REACT_APP_FRONTEND_URL}/roles`];
        setUserRoles(rolesClaim ? rolesClaim : []);
      });
  }, [isAuthenticated]);

  useEffect(() => {
    // Wait for authentication to complete before updating loading state
    if (isAuthenticated && userRoles) {
      setIsLoading(false);
    }

    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5 seconds
    return () => clearTimeout(loadingTimeout); // Clear the timer if authentication completes before the timeout
  }, [isAuthenticated]);

  if (isLoading) {
    return (
      <div>
        <div style={loadingStyle}>
          Authenticating...
          <br />
          {/* <p style={messageStyle}>
                If this is taking too long, it is possible that you are not currently logged in.
            </p> */}
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    // User not authenticated
    // loginWithRedirect();
    // return;
    return <Navigate to="/access-denied" />;
  }

  let userRolesArray;

  if (!Array.isArray(userRoles)) userRolesArray = [userRoles];
  else userRolesArray = userRoles;

  if (
    (accessLevel === "doctor" && !userRolesArray.includes("doctor")) ||
    (accessLevel === "historian" && !userRolesArray.includes("historian"))
  ) {
    // User's email does not match the expected email for the guard, deny access
    // console.log("accessLevel:", accessLevel);
    return <Navigate to="/access-denied" />;
  }

  // User's email is allowed for the guard, render the child components
  return children;
};

const loadingStyle = {
  display: "flex",
  flexDirection: "column", // Stack elements vertically
  justifyContent: "center",
  alignItems: "center",
  height: "90vh", // Vertically center the message
  fontSize: "1.5rem",
  color: "#003396",
};

const messageStyle = {
  fontSize: "1rem", // Adjust the font size as needed
  color: "#666", // Adjust the text color as needed
  textAlign: "center", // Center the text horizontally
};

export default RouteGuard;
