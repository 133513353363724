import ErrorPage from "./ErrorPage";
import { ErrorBoundary } from "react-error-boundary";

export default function ReactErrorBoundary(props) {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={(error, errorInfo) => {
                // log the error
                // console.log("Error caught!");  
                console.error(error);  
                console.error(errorInfo);
            }}
            onReset={() => {
                // reloading the page to restore the initial state
                // of the current page
                // console.log("reloading the page...");
                window.location.reload();
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}

// EXAMPLE OF USING THIS FOR API CALLS ASYNC FUNCTIONS:

// import { useErrorHandler } from "react-error-boundary";    <-----

// export default function MyPageComponent(props) {
//     const handleError = useErrorHandler();      <-----

//     useEffect(() => {
//       // API call
//       fetchSomeData().then(() => {
//           // ...
//       }).catch((e) => {
//           // propagate the error to the error boundary
//           handleError(e);      <-----
//       })
//     }, [])

//     // return ...
// }