import React, { useState, useRef, useEffect } from "react";
// import { flushSync } from "react-dom";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import BoxComponent from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { destroySession } from "./Utils";

export default function IdleTimer({ shouldDestroy }) {
  const navigate = useNavigate();

  // Expire session upon reloading/leaving page
  useEffect(() => {
    const performCleanup = () => {
      if (shouldDestroy()) {
        // This call works for page refreshes
        destroySession();
      }
    };

    const handleBeforeUnload = (e) => {
      e.returnValue = "";
      window.addEventListener("unload", performCleanup);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // This call works for browser back button click
      if (shouldDestroy()) {
        destroySession();
      }
      window.removeEventListener("unload", performCleanup);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // // Warning confirmation popup when leaving page
  // const [ignorePrompt, setIgnorePrompt] = useState(false);
  // usePrompt(
  //   "Are you sure you want to leave? Your session will expire if you exit the page.",
  //   !ignorePrompt
  // );

  // Session user idle warning popup
  const SESSION_DURATION = 2 * 60 * 1000; // 2 minutes
  const WARNING_TIME = 100 * 1000; // Show warning 20 sec before session expires
  let sessionTimeoutWarning = useRef();
  let sessionTimeout = useRef();

  const [idleWarningOpen, setidleWarningOpen] = useState(false);
  const startSessionTimer = () => {
    // Clear previous timer
    clearTimeout(sessionTimeoutWarning.current);
    clearTimeout(sessionTimeout.current);

    sessionTimeoutWarning.current = setTimeout(() => {
      // Display warning
      setidleWarningOpen(true);
    }, WARNING_TIME);

    sessionTimeout.current = setTimeout(() => {
      // Timeout. Redirect to pin
      //   flushSync(() => {
      //     setIgnorePrompt(true);
      //   });
      navigate("/patient-pin", { replace: true });
    }, SESSION_DURATION);
  };

  // Start session timer
  useEffect(() => {
    startSessionTimer();

    return () => {
      if (sessionTimeoutWarning.current)
        clearTimeout(sessionTimeoutWarning.current);
      if (sessionTimeout.current) clearTimeout(sessionTimeout.current);
    };
  }, []);

  // Reset session expiration time
  const renewSession = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/patient/renew-session`,
        null,
        { withCredentials: true }
      );

      if (response.status === 200) {
        setidleWarningOpen(false);
        startSessionTimer();
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        // flushSync(() => {
        //   setIgnorePrompt(true);
        // });
        navigate("/patient-pin", { replace: true });
      }
    }
  };

  return (
    <Modal
      open={idleWarningOpen}
      BackdropProps={{
        sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        onClick: null, // Disable closing the modal by clicking on the backdrop
      }}
    >
      <React.Fragment>
        <BoxComponent
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            minWidth: 550,
            maxHeight: "40vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" gutterBottom fontWeight="bold">
            Are you still there?
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html:
                "Your session will expire soon due to inactivity. Please click confirm to stay logged in to this session.",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right" }}
            onClick={renewSession}
          >
            Confirm
          </Button>
        </BoxComponent>
      </React.Fragment>
    </Modal>
  );
}
