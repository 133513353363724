import React from "react";
import "./DeleteConfirmation.css";

function DeleteConfirmation({ confirmDelete, cancelDelete }) {
  return (
    <div className="delete-confirmation">
      <div className="delete-confirmation-content">
        <p>Are you sure you want to DELETE this patient?</p>
        <div className="delete-confirmation-buttons">
          <button className="delete-button" onClick={confirmDelete}>
            Delete
          </button>
          <button onClick={cancelDelete}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmation;