import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import PatientForm from "./PatientForm";

export default function PatientFormDataHandler() {
  const [patientFormData, setPatientFormData] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  let { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/patient/get-patient-answers/${id}`,
          { withCredentials: true }
        );

        setPatientFormData(response.data.answers);
        setStatusCode(response.status);
        // console.log("Fetched data from patient forms:", response.data);
      } catch (error) {
        console.error("Failed to fetch patient form data: ", error);
        if (error.response?.status === 401)
          navigate("/patient-pin", { replace: true });
        if (error.response) {
          setStatusCode(error.response.status); // Set the status code if there's an error response
        } else {
          setStatusCode("No response received");
        }
      }
    };
    fetchData();
  }, [id]);

  //   useEffect(() => {
  //     if (statusCode !== null) {
  //       console.log("Status code:", statusCode); // Log the status code whenever it changes
  //     }
  //   }, [statusCode]); // Adding an effect that runs whenever the status code changes

  return statusCode === 200 && patientFormData ? (
    <PatientForm patientFormData={patientFormData} />
  ) : statusCode === 404 ? (
    <PatientForm patientFormData={null} />
  ) : statusCode === 401 ? (
    <div>Access denied. Redirecting...</div>
  ) : (
    <div>Loading...</div>
  );
}
