import React from 'react';
import './ErrorPage.css';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ErrorPage = (props) => {

  const handleGoBack = () => {
    try {
      window.history.back();
    } catch (error) {
      console.error("Error occurred while navigating back:", error);
    }
  };

  return (
    <div className="error-container">
      <h1 className="error-message">Oops! Something went wrong.</h1>
      <p className="error-description">We're sorry, but an unexpected error occurred.</p>
      <p className="error-instructions">
        <strong>For Patients:</strong>
        <br />
        Please see Historian for help.
        <br /><br />
        <strong>For Historians/Doctors:</strong>
        <br />
        Please reach out to our support team for assistance at <a href="mailto:ihsandatasolutions@gmail.com">ihsandatasolutions@gmail.com</a>.
      </p>
      <div class="error-button-container">
        <Button onClick={handleGoBack} className="go-back-button">
          Back to Previous Page
        </Button>
        <Button as={Link} to="/home" className="error-home-button">
          Go back to Home
        </Button>
      </div>
      {props.resetErrorBoundary && (
        <div>
          <button className="retry-button" onClick={props.resetErrorBoundary}>
            Try Again
          </button>
        </div>
      )}

    </div>
  );
};

export default ErrorPage;
