import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import "./PhysicalExams.css";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useAuthentication } from "../auth";

import { AccordionProvider } from "./Accordion/AccordionProvider";
import PhysicalExamAccordion from "./Accordion/PhysicalExamAcordion";
import PhysicalExamsQuestions from "../physical-exams.json";
import "./PhysicalExams.css";

function PhysicalExams({ selectedBodyParts, physicalExamData, setCompletion }) {
  let { id } = useParams();

  const [formData, setFormData] = useState({
    bodyParts: selectedBodyParts,
    forms: {},
  });

  const errorMessageTimer = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const ERROR_TIME = 5 * 1000;

  const startErrorTimer = () => {
    // Clear previous timer
    clearTimeout(errorMessageTimer.current);
    setErrorMessage(true);

    errorMessageTimer.current = setTimeout(() => {
      // Display warning
      setErrorMessage(false);
    }, ERROR_TIME);
  };

  // const handleFinalSubmit = async () => {
  //   if (checkAllCompleted() === true)
  //     console.log("Finished: " + checkAllCompleted());
  //   else {
  //     console.log("Finished: " + checkAllCompleted());
  //     startErrorTimer();
  //   }
  //   // console.log("FORM DATA: ", formData);
  //   // try{
  //   // await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor-physical-exams/${id}`, formData)
  //   //   console.log("Saved successfully!");
  //   //   toast.success("Physical Exam Forms Submitted Successfully!", {
  //   //     position: "top-center",
  //   //     autoClose: 3000, // milliseconds
  //   //   });
  //   // } catch(error) {
  //   //   console.error(error);
  //   // }
  // };

  return (
    <div className="physical-exams-container">
      <AccordionProvider>
        {selectedBodyParts.map((bodyPart, index) => (
          <BodyPartFormComponent
            key={bodyPart}
            bodyPart={bodyPart}
            physicalExamData={physicalExamData}
            id={id}
            setCompletion={setCompletion}
          />
        ))}
      </AccordionProvider>
      <div style={{ alignSelf: "flex-end" }}>
        {errorMessage && (
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              border: "solid 1px black",
              display: "inline",
            }}
          >
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Please complete all forms before submitting{" "}
              <span className="error-text">&#9888;</span>
            </span>
          </div>
        )}
        {/* <button
          className="physical-exams-save-all-btn"
          onClick={handleFinalSubmit}
        >
          Submit All
        </button> */}
      </div>
    </div>
  );
}

function BodyPartFormComponent({
  bodyPart,
  physicalExamData,
  id,
  setCompletion,
  accordionid,
}) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [bodyPartQuestions, setBodyPartQuestions] = useState([]);

  useEffect(() => {
    const fetchBodyPartsQuestions = async (query) => {
      try {
        if (isAuthenticated) {
          let accessToken;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (error) {
            console.error("Error fetching access token:", error);
          }
          const options = {
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_URL}/get-phys-exam/${query}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const response = await Axios.request(options);

          setBodyPartQuestions(response.data.questions);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // const fetchBodyPartsQuestions = (query) =>
    //   setBodyPartQuestions(
    //     Array(PhysicalExamsQuestions)[0].find((exam) => exam.bodyPart === query)
    //       .questions
    //   );

    switch (bodyPart) {
      case "C Spine":
        fetchBodyPartsQuestions("CSpine");
        break;
      case "Elbows":
        fetchBodyPartsQuestions("Elbows");
        break;
      case "Shoulder":
        fetchBodyPartsQuestions("Shoulder");
        break;
      case "Thoracic Spine":
        fetchBodyPartsQuestions("ThoracicSpine");
        break;
      case "Hips":
        fetchBodyPartsQuestions("Hips");
        break;
      case "Lumbosacral Spine":
        fetchBodyPartsQuestions("LumbosacralSpine");
        break;
      case "Hands":
        fetchBodyPartsQuestions("Hands");
        break;
      case "Knees":
        fetchBodyPartsQuestions("Knees");
        break;
      case "Ankle/Feet":
        fetchBodyPartsQuestions("AnkleFeet");
        break;
      default:
        return null;
    }
  }, [bodyPart]);

  return (
    <PhysicalExamAccordion
      accordionid={accordionid}
      title={bodyPart}
      bodyPart={bodyPart}
      questions={bodyPartQuestions}
      defaultValues={physicalExamData ? physicalExamData[String(bodyPart)] : {}}
      sendToURL={`${process.env.REACT_APP_BACKEND_URL}/doctor-physical-exams/${id}`}
      note={true}
      setCompletion={setCompletion}
    />
  );
}

export default PhysicalExams;
