import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
// import samplePdf1 from "../ppp_template_OUTPUT_123.pdf";
// import samplePdf2 from "../sample.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./PdfViewer.css";
import {
  BsChevronBarLeft,
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarRight,
  BsPlusLg,
  BsDashLg,
} from "react-icons/bs";
import { FiRefreshCw, FiMinimize2 } from "react-icons/fi";

export default function PdfViewer({ url }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  //   useEffect(() => {
  //     axios
  //       .get(url, {
  //         responseType: "arraybuffer",
  //       })
  //       .then((response) => {
  //         const blob = new Blob([response.data], { type: "application/pdf" });
  //         const url = URL.createObjectURL(blob);
  //         setpdfSrc(url);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }, [url]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  // Zoom In/Out functionality
  function changeScale(offset) {
    const oldScale = scale;
    const newScale = oldScale + offset;

    // Get the current scroll position.
    const currentScrollLeft = containerRef.current.scrollLeft;
    const currentScrollTop = containerRef.current.scrollTop;

    // Get the current view dimensions.
    const currentViewWidth = containerRef.current.clientWidth;
    const currentViewHeight = containerRef.current.clientHeight;

    // Calculate the center of the current view.
    const centerX = currentScrollLeft + currentViewWidth / 2;
    const centerY = currentScrollTop + currentViewHeight / 2;

    // Set the new scale.
    setScale(newScale);

    // Use setTimeout to delay the adjustment of the scroll position.
    setTimeout(() => {
      // Calculate the new scroll position based on the center of the current view.
      const newScrollLeft =
        (centerX * newScale) / oldScale - currentViewWidth / 2;
      const newScrollTop =
        (centerY * newScale) / oldScale - currentViewHeight / 2;

      // Set the new scroll position.
      containerRef.current.scrollLeft = newScrollLeft;
      containerRef.current.scrollTop = newScrollTop;
    }, 0);
  }
  function decScale() {
    changeScale(-0.25);
  }
  function incScale() {
    changeScale(0.25);
  }
  function resetScale() {
    changeScale(-(scale - 1));
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const [retries, setRetries] = useState(0);
  const containerRef = useRef(null);

  const [doctorIncomplete, setDoctorIncomplete] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
        marginBottom: "20px",
        width: "80%",
        alignSelf: "center",
      }}
    >
      <div className="controls-pdf">
        <p>
          {numPages ? pageNumber || (numPages ? 1 : "--") : "--"} /{" "}
          {numPages || "--"}
        </p>
        <div className="container-navigation-pdf">
          <div className="button-navigation-container">
            <button
              type="button"
              onClick={() => {
                if (pageNumber > 1) setPageNumber(1);
              }}
              className="button-navigation-pdf"
            >
              <BsChevronBarLeft
                className="navigation-arrow"
                style={{ marginLeft: "3px" }}
              />
            </button>
            <div style={{ display: "flex" }}>
              <button
                type="button"
                onClick={() => {
                  if (pageNumber > 1) previousPage();
                }}
                className="button-navigation-pdf"
              >
                <BsChevronLeft
                  className="navigation-arrow"
                  style={{ marginRight: "3px" }}
                />
              </button>
              <button
                type="button"
                onClick={() => {
                  if (pageNumber < numPages) nextPage();
                }}
                className="button-navigation-pdf"
              >
                <BsChevronRight
                  className="navigation-arrow"
                  style={{ marginLeft: "3px" }}
                />
              </button>
              {/* <button
                className="button-retry"
                style={{ display: "inline", alignSelf: "center" }}
                onClick={() => {
                  setRetries((prev) => prev + 1);
                }}
              >
                <FiRefreshCw style={{ verticalAlign: "middle" }}></FiRefreshCw>
              </button> */}
            </div>
            <button
              type="button"
              onClick={() => {
                if (pageNumber < numPages) setPageNumber(numPages);
              }}
              className="button-navigation-pdf"
            >
              <BsChevronBarRight
                className="navigation-arrow"
                style={{ marginRight: "3px" }}
              />
            </button>
          </div>
          <div
            style={{
              gridColumn: 3,
              width: "100%",
              borderLeft: "2px solid #72797f",
              borderRadius: "1px",
            }}
          >
            <div className="navigation-zoom-container">
              <button
                type="button"
                onClick={() => {
                  if (scale > 0.5) decScale();
                }}
                className="button-navigation-pdf"
              >
                <BsDashLg className="navigation-zoom" />
              </button>
              <button
                type="button"
                onClick={() => {
                  if (scale < 3.0) incScale();
                }}
                className="button-navigation-pdf"
              >
                <BsPlusLg className="navigation-zoom" />
              </button>
              <button
                type="button"
                onClick={() => {
                  if (scale !== 1.0) resetScale();
                }}
                className="button-navigation-pdf"
                style={{ marginLeft: "20px" }}
              >
                <FiMinimize2
                  className="navigation-zoom"
                  style={{ strokeWidth: "2px", height: "90%", width: "90%" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-pdf" id="container-pdf">
        <div
          style={{
            display: "flex",
            overflow: "auto",
            width: "100%",
            height: "100%",
          }}
          ref={containerRef}
        >
          <div style={{ margin: "auto" }}>
            <Document
              noData=""
              loading={<div className="loading">Generating document</div>}
              onLoadError={(error) => {
                if (error.status === 403) setDoctorIncomplete(true);
              }}
              error={
                doctorIncomplete === true ? (
                  <div style={{ alignItems: "center", textAlign: "center" }}>
                    Please complete{" "}
                    <strong>
                      <u>ENTIRE</u>
                    </strong>{" "}
                    form including{" "}
                    <strong>
                      <u>DOCTOR-SIDE</u>
                    </strong>{" "}
                    questions before generating document.
                  </div>
                ) : (
                  <div style={{ alignItems: "center" }}>
                    Failed to load. Retry{" "}
                    <button
                      className="button-retry"
                      style={{ display: "inline" }}
                      onClick={() => {
                        setRetries((prev) => prev + 1);
                      }}
                    >
                      <FiRefreshCw
                        style={{ verticalAlign: "middle" }}
                      ></FiRefreshCw>
                    </button>
                  </div>
                )
              }
              key={`document-${retries}`}
              className="document-pdf"
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                noData=""
                loading=""
                pageNumber={pageNumber}
                scale={scale}
              />
            </Document>
          </div>
        </div>
      </div>
    </div>
  );
}
