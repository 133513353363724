import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
/* 
  Originally used BrowserRouter, but needed to use unstable_HistoryRouter in order to use usePrompt/useBlocker. 
  Source: https://github.com/remix-run/react-router/issues/8139#issuecomment-1247080906
*/
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactErrorBoundary from "./pages/ErrorBoundary";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import history from "./utils/history";
import "./axiosConfig"; // Importing to ensure the Axios global api call timeout configuration is set at startup

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider {...providerConfig}>
    <HistoryRouter history={createBrowserHistory({ window })}>
      <ReactErrorBoundary>
        <App />
      </ReactErrorBoundary>
    </HistoryRouter>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
