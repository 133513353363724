import React, { useState, useEffect } from "react";
import "./Tab.css";
import DoctorPatientDataHandler from "../pages/DoctorPatientDataHandler";
import BodyPartsSelection from "./BodyPartsSelection";
// import CurrentComplaints from "./CurrentComplaints";
import PhysicalExams from "./PhysicalExams";
import DoctorPatientResponses from "../pages/DoctorPatientResponses";
import axios from "axios";
import { useParams } from "react-router-dom";
import PhysicalExamsDataHandler from "./PhysicalExamsDataHandler";
// import CurrentComplaintsDataHandler from "./CurrentComplaintsDataHandler";
import { useAuthentication } from "../auth";

function Tabs({
  setPatientDoctorCompletion,
  setPhysicalExamCompletion,
  physicalExamCompletion,
}) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  let { id } = useParams();
  const fetchData = async () => {
    try {
      if (isAuthenticated) {
        let accessToken;
        try {
          accessToken = await getAccessTokenSilently();
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
        const options = {
          method: "GET",
          url: `${process.env.REACT_APP_BACKEND_URL}/get-body-parts/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.request(options);
        setSelectedBodyParts(response.data.body_parts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleBodyPartsSelected = (bodyParts) => {
    setSelectedBodyParts(bodyParts);
  };

  return (
    <div className="tabs-container">
      <div className="tabs-header">
        <TabHeader
          title="Questionnaire"
          active={activeTab === 0}
          onClick={() => handleTabClick(0)}
        />
        <TabHeader
          title="Body Parts"
          active={activeTab === 1}
          onClick={() => handleTabClick(1)}
        />
        {/* <TabHeader
          title="Current Complaints"
          active={activeTab === 2}
          onClick={() => handleTabClick(2)}
        /> */}
        <TabHeader
          title="Physical Exams"
          active={activeTab === 2}
          onClick={() => handleTabClick(2)}
        />
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <DoctorPatientDataHandler
            setCompletion={setPatientDoctorCompletion}
          />
        )}
        {activeTab === 1 && (
          <BodyPartsSelection
            onBodyPartsSelected={handleBodyPartsSelected}
            caseId={id}
            sendToURL={`${process.env.REACT_APP_BACKEND_URL}/bodyparts/${id}`}
            fetchFromURL={`${process.env.REACT_APP_BACKEND_URL}/get-body-parts/${id}`}
            setCompletion={setPhysicalExamCompletion}
            completion={physicalExamCompletion}
          />
        )}
        {/* {activeTab === 2 && (
          <CurrentComplaintsDataHandler
            selectedBodyParts={selectedBodyParts}
            caseId={id}
          />
        )} */}
        {activeTab === 2 && (
          <PhysicalExamsDataHandler
            selectedBodyParts={selectedBodyParts}
            caseId={id}
            setCompletion={setPhysicalExamCompletion}
            completion={physicalExamCompletion}
          />
        )}
      </div>
    </div>
  );
}

function TabHeader({ title, active, onClick }) {
  return (
    <div className={`tab-header ${active ? "active" : ""}`} onClick={onClick}>
      {title}
    </div>
  );
}

export default Tabs;
