import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Text,
  Rect,
  Group,
} from "react-konva";
import BodyImage from "../../../images/body-diagram.png";
import Dash from "../../../images/dash.png";
import Circle from "../../../images/circle.png";
import Cross from "../../../images/cross.png";
import Slash from "../../../images/slash.png";
import I from "../../../images/i.png";
import S from "../../../images/s.png";
import B from "../../../images/b.png";
import TrashImage from "../../../images/trash-sharp.png";
import Legend from "./Legend";
import html2canvas from "html2canvas";

import "./Drawing.css";

const INITIAL_WIDTH = 600;
const INITIAL_HEIGHT = 400;

export default forwardRef(function Drawing(
  { setImage, setIsDirty, ...props },
  ref
) {
  // indicates when each image is ready
  const [trashImageReady, settrashImageReady] = useState(null);
  const [bodyImageReady, setbodyImageReady] = useState(null);
  const [dashReady, setdashReady] = useState(null);
  const [circleReady, setcircleReady] = useState(null);
  const [crossReady, setcrossReady] = useState(null);
  const [slashReady, setslashReady] = useState(null);
  const [iReady, setiReady] = useState(null);
  const [sReady, setsReady] = useState(null);
  const [bReady, setbReady] = useState(null);

  // generic image loading function
  const loadImages = (image, setter) => {
    const img = new window.Image();
    img.src = image;
    img.onload = () => {
      setter(img);
    };
  };

  // loads all images
  useEffect(() => {
    loadImages(TrashImage, settrashImageReady);
    loadImages(BodyImage, setbodyImageReady);
    loadImages(Dash, setdashReady);
    loadImages(Circle, setcircleReady);
    loadImages(Cross, setcrossReady);
    loadImages(Slash, setslashReady);
    loadImages(I, setiReady);
    loadImages(S, setsReady);
    loadImages(B, setbReady);
  }, []);

  const containerRef = useRef(null);
  const [width, setWidth] = useState(INITIAL_WIDTH);
  const [height, setHeight] = useState(INITIAL_HEIGHT);
  const scaleWidthRatio = width / INITIAL_WIDTH; // Adjust based on the initial width
  const scaleHeightRatio = height / INITIAL_HEIGHT; // Adjust based on the initial height
  const trashDimensions = width / 10; // trash icon takes up 10% of width on both sides (square)

  const handleDragStart = (e) => {
    const id = e.target.id();
    setShapes((prevShapes) =>
      prevShapes.map((shape) => {
        return {
          ...shape,
          isDragging: shape.id === id,
        };
      })
    );
  };

  /* 
    Keeps track of which shape is being dragged.
    If shape is dropped onto trash icon (upper right corner), it is deleted.
  */
  const handleDragEnd = (e) => {
    const { x, y } = e.target.getAbsolutePosition();
    const id = e.target.id();

    if (
      x > width - trashDimensions * 1.3 &&
      x < width &&
      y > 0 &&
      y < trashDimensions
    ) {
      setShapes(shapes.filter((shape) => shape.id !== id));
    } else {
      setShapes((prevShapes) =>
        prevShapes.map((shape) => {
          if (shape.id === id)
            return {
              ...shape,
              isDragging: false,
              // Returns current position to INITIAL position to preserve original value
              // This allows us to synchronize all shapes to share the initial coordinate range,
              // allowing every shape to correctly scale to the current coordinate range.
              x: x / scaleWidthRatio,
              y: y / scaleHeightRatio,
            };
          else
            return {
              ...shape,
              isDragging: false,
            };
        })
      );
    }
  };

  /* 
    We have to manually set the Stage component size here because 
    it only takes in absolute values (e.g. 10px) as dimensions and its
    parent container dimensions are restricted by its child Stage dimensions. 
    In other words, the parent container cannot shrink below the size of 
    the Stage component which cannot take in relative values (width: 100%), 
    so there is a circular dependency. Therefore, rather than have the 
    Stage component automatically shrink with its parent container, we must manually
    set its size.
  */
  useEffect(() => {
    function updateSize() {
      if (containerRef.current) {
        setWidth(containerRef.current.clientWidth);
        // Multiply by 9 / 16 to preserve parent container aspect ratio
        setHeight(containerRef.current.clientWidth * (9 / 16));
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // unique id for each generated shape. Increments every time it's used.
  const idCounter = useRef(1);
  const [shapes, setShapes] = useState([]);

  useEffect(() => {
    if (shapes.length > 0) {
      setIsDirty(true);
    }
  }, [shapes]);

  function addShape(type) {
    /*
      jitter is used to ensure that two shapes won't be in the same exact starting position,
      which would otherwise render them stacked on top of each other
    */
    const X_JITTER = 30 * Math.random();
    const Y_JITTER = 30 * Math.random();
    setShapes((prevShapes) => {
      prevShapes.push({
        type: type,
        // assign unique shape id, then increment id counter
        id: (idCounter.current++).toString(),
        isDragging: false,
        x: INITIAL_WIDTH / 2 + X_JITTER,
        y: 50 + Y_JITTER,
      });
      const newShapes = [...prevShapes];
      return newShapes;
    });
  }

  function renderShape(shape) {
    let width;
    let height;
    let image;
    const dragScale = 1.15;

    switch (shape.type) {
      case "dash":
        width = 35;
        height = 5;
        image = dashReady;
        break;
      case "circle":
        width = 35;
        height = 12;
        image = circleReady;
        break;
      case "cross":
        width = 35;
        height = 12;
        image = crossReady;
        break;
      case "slash":
        width = 35;
        height = 12;
        image = slashReady;
        break;
      case "i":
        width = 30;
        height = 20;
        image = iReady;
        break;
      case "s":
        width = 30;
        height = 20;
        image = sReady;
        break;
      case "b":
        width = 30;
        height = 20;
        image = bReady;
        break;
      default:
        console.warn("Shapes array - Incorrect Shape Type: " + shape.type);
        return <p>INCORRECT SHAPE TYPE</p>;
    }
    return (
      image && (
        <KonvaImage
          draggable
          key={shape.id}
          id={shape.id}
          image={image}
          x={shape.x * scaleWidthRatio}
          y={shape.y * scaleHeightRatio}
          width={width * scaleWidthRatio * (shape.isDragging ? dragScale : 1)}
          height={
            height * scaleHeightRatio * (shape.isDragging ? dragScale : 1)
          }
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        />
      )
    );
  }

  const stageRef = useRef(null);

  /* 
  Toggles Legend's "interactive" mode ('+' buttons). We want to remove these upon
  screenshotting the drawing component, then revert the legend to its previous state 
  */
  const [interactive, setInteractive] = useState(props.interactive);

  // capture image for parent component to use
  const captureImageFromCanvas = async (pxRat) => {
    setInteractive(false);

    // Wait for the next render cycle, in hopes that the screenshot occurs AFTER buttons disappear
    await new Promise(requestAnimationFrame);
    const container = containerRef.current;
    if (!container) return;

    const canvas = await html2canvas(container);
    const dataUrl = canvas.toDataURL({ pixelRatio: pxRat }); // pixelRatio ensures better image quality

    setInteractive(props.interactive);
    return dataUrl;
  };
  // Make the above function available to parent component
  useImperativeHandle(ref, () => ({
    captureImageFromCanvas,
  }));

  /*
    Wait for interactive mode to turn false and hide '+' buttons before screenshotting drawing component, 
    then export and return initial interactive state
  */
  useEffect(() => {
    if (interactive === false) {
      // exportAsImage(containerRef.current, "body_diagram_output.png");
      setInteractive(props.interactive);
    }
  }, [interactive]);

  return (
    <div id="container-drawing-component">
      <div id="header-drawing">
        <h4>
          PLEASE MARK THE AREAS ON YOUR BODY WHERE YOU FEEL THE DESCRIBED
          SENSATIONS.
        </h4>
        <div
          style={{
            display: "inline-block",
            alignSelf: "center",
          }}
        >
          <p style={{ textAlign: "left", margin: "0" }}>
            Put an{" "}
            <strong>
              <u>S</u>
            </strong>{" "}
            in the afflicted area if the pain is ON the skin.
            <br /> Put an{" "}
            <strong>
              <u>I</u>
            </strong>{" "}
            in the afflicted area if the pain is INSIDE the body.
            <br /> Put a{" "}
            <strong>
              <u>B</u>
            </strong>{" "}
            if BOTH apply to the areas that you mark.
          </p>
        </div>
        <p>
          Using the appropriate symbol, mark areas of radiation. Include all
          affected areas:
        </p>
      </div>
      {/* <button
        onClick={() => {
          setInteractive(false);
        }}
      >
        Export as Image
      </button> */}
      <div id="section-drawing" ref={containerRef}>
        <Legend
          style={{ gridRow: 1 }}
          addShape={addShape}
          idCounter={idCounter}
          interactive={interactive}
        />
        <div id="container-canvas">
          <div style={{ width: "100%", height: "100%" }}>
            <Stage width={width} height={height} ref={stageRef}>
              <Layer>
                {bodyImageReady && (
                  <KonvaImage
                    image={bodyImageReady}
                    width={width}
                    height={height}
                  />
                )}
                {/* trash can icon */}
                <Group
                  x={width - trashDimensions}
                  y={0}
                  width={trashDimensions}
                  height={trashDimensions}
                  onDragOver={(e) => e.evt.preventDefault()}
                >
                  <Rect
                    width={trashDimensions}
                    height={trashDimensions}
                    fill={"white"}
                    stroke={"black"}
                  />
                  {trashImageReady ? (
                    <KonvaImage
                      image={trashImageReady}
                      x={(trashDimensions * 15) / 70}
                      y={(trashDimensions * 15) / 70}
                      width={(trashDimensions * 4) / 7}
                      height={(trashDimensions * 4) / 7}
                    />
                  ) : (
                    <Text
                      x={0}
                      // subtract half of font size
                      y={trashDimensions / 2 - trashDimensions / 10}
                      width={trashDimensions}
                      align="center"
                      text="Trash"
                      fontSize={trashDimensions / 5}
                    />
                  )}
                </Group>
                {shapes.map((shape) => {
                  return renderShape(shape);
                })}
              </Layer>
            </Stage>
          </div>
        </div>
      </div>
    </div>
  );
});
