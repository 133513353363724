import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './AccessDeniedPage.css'; // Import the CSS file for styling

const AccessDeniedPage = () => {
    const headerStyle = {
        fontSize: '36px',
        marginBottom: '20px',
      };
    
      const paragraphStyle = {
        fontSize: '18px',
        marginBottom: '30px',
      };

  return (
    <div className="access-denied-container">
      <div className="access-denied-content">
        <h1 style={headerStyle}>Access Denied</h1>
        <p style={paragraphStyle}>Access to this page is restricted. Please log in with the appropriate credentials to gain access.</p>
        <Link to="/" className="access-denied-home-button">
          Return to Home
        </Link>
      </div>
    </div>
  );
};

export default AccessDeniedPage;
