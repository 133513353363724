import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

// Custom hook to handle authentication-related state and actions
const useAuthentication = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(null);

  // const [userRoles, setUserRoles] = useState(null);
  // useEffect(() => {
  //   if (isAuthenticated === true)
  //     getIdTokenClaims().then((claims) => {
  //       const rolesClaim =
  //         claims[`${process.env.REACT_APP_FRONTEND_URL}/roles`];
  //       setUserRoles(rolesClaim ? rolesClaim : []);
  //     });
  // }, [isAuthenticated]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  // useEffect(() => {
  //     // This effect will run whenever 'role' state changes
  //     console.log('Role in useEffect:', role);
  //     console.log("Authorized: ", isAuthenticated)
  //     console.log("User in Effect: ", user)
  //   }, [isAuthenticated]);

  const handleRedirectByRole = (roleToRedirect) => {
    if (!isAuthenticated) {
      // User is not authenticated, prompt them to log in
      loginWithRedirect({
        appState: { role: roleToRedirect },
      });
      return;
    }

    // console.log("Role:", roleToRedirect); // Role is now accessible here
    // Determine the redirect URL based on the role
    const redirectUrl =
      roleToRedirect === "doctor"
        ? "/doctor-patient-forms"
        : "/historian-patient-forms";
    // console.log("Redirect URL:", redirectUrl);

    // Use navigate function to redirect the user to the appropriate page
    navigate(redirectUrl);
  };

  // useEffect(() => {
  //   const fetchAccessToken = async () => {
  //     if (isAuthenticated) {
  //       try {
  //         const token = await getAccessTokenSilently();
  //         console.log("ACCESS TOKEN:", token);

  //         const decodedToken = jwt_decode(token);
  //         console.log("DECODED TOKEN:", decodedToken);
  //         // setAccessToken(token);
  //       } catch (error) {
  //         console.error('Error fetching access token:', error);
  //       }
  //     }
  //   };

  //   fetchAccessToken();
  // }, [isAuthenticated, getAccessTokenSilently]);

  //   const fetchUserData = async (accessToken) => {

  //   const userAuth0Id = encodeURIComponent(user ? user.sub : '');
  //   const response = await fetch(`https://dev-33e2n6otl5ci0qxy.us.auth0.com/api/v2/users/${userAuth0Id}`, {
  //   method: 'GET',
  //   headers: {
  //     'Authorization': `Bearer ${accessToken}`
  //   }
  // });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch user data');
  //     }

  //     return response.json();
  //   };

  //   const getUserRoles = (userData) => {
  //     return userData && userData.roles ? userData.roles : [];
  //   };

  // fetchUserData(accessToken)
  //   .then(userData => {
  //     const userRoles = getUserRoles(userData);
  //     console.log('User Roles:', userRoles);
  //   })
  //   .catch(error => console.error('Error fetching user data:', error));

  return {
    isDropdownOpen,
    user,
    // userRoles,
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
    toggleDropdown,
    closeDropdown,
    logoutWithRedirect,
    handleRedirectByRole,
    getAccessTokenSilently,
  };
};

export { useAuthentication };
