import React, { useEffect, useState } from "react";
import { getNestedValue } from "../../utils/Utils";
import { useFieldArray } from "react-hook-form";

import "./question.css";

// component that allows user to add more fields if needed

// 'options[]' will be the label of each textbox

// indicate how many fields appear by default with "min"
export default function BoxArray(props) {
  const {
    register,
    registerName,
    data,
    control,
    className,
    number,
    errorData,
  } = props;

  /*
      Dynamically add new text boxes if needed

      MUST make sure that there are no duplicate question ids.
      Otherwise, two BoxArray objects will mistakenly sync the same number of fields.
  */
  const { fields, append, remove } = useFieldArray({
    name: registerName,
    control,
  });

  const above = data.above === true ? true : false;

  // get number of questions
  const questionNum = data.options.length;

  const structure = () => {
    const struct = {};
    for (let i = 1; i < questionNum; i++) {
      struct[data.options[i].toLowerCase().replace(/\W+/g, "_")] = undefined;
    }
    return struct;
  };

  /* 
    From my understanding, these are not asynchronous funcions, but for some reason,
    if you remove the 'async' identifiers, the 'Add' and 'Remove' buttons break.
  */
  const [initialLoad, setinitialLoad] = useState(false);
  useEffect(() => {
    async function setMinimumFields() {
      // render additional fields to number of 'min(imum)' structure property
      if (data.min !== undefined && Number.isInteger(data.min)) {
        const difference = data.min - fields.length;

        for (let i = 0; i < difference; i++) {
          await append(structure);
        }
      }
      // if prefilling values, render up to number of submitted fields
      const defaultBoxArrayValues = getNestedValue(
        control?._defaultValues,
        registerName
      );

      if (
        Array.isArray(defaultBoxArrayValues) &&
        defaultBoxArrayValues.length > fields.length
      ) {
        const difference = defaultBoxArrayValues.length - fields.length;
        for (let i = 0; i < difference; i++) {
          await append(structure);
        }
      }
      await setinitialLoad(true);
    }
    setMinimumFields();
  }, []);

  return (
    <div
      className={"question " + className}
      style={{
        width: "100%",
        fontSize: 18,
      }}
    >
      {above === true && (
        <label>
          {`${number}. `}
          <span
            dangerouslySetInnerHTML={{
              __html: data.question.replace(/\n/g, "<br />"),
            }}
          />
          {data.required === true && <span className="error-text">*</span>}
        </label>
      )}

      {initialLoad && (
        <div style={{ marginTop: "10px" }}>
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                {/* Question Container */}
                <div
                  style={
                    above === true
                      ? {
                          display: "grid",
                          gridTemplateColumns: "0fr 1fr",
                          width: "100%",
                          justifyContent: "left",
                          fontSize: 18,
                        }
                      : {
                          display: "grid",
                          gridTemplateColumns: "2fr 8fr",
                          width: "100%",
                          justifyContent: "left",
                          fontSize: 18,
                        }
                  }
                >
                  <div
                    className="form-control"
                    style={{
                      width: "100%",
                      display: "grid",
                      gridColumn: "2",
                      gridTemplateColumns: `repeat(5, 1fr)`,
                      columnGap: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    {/* Sub Questions */}
                    {data.options.map((o) => {
                      const registerNameCopy = `${registerName}.${index}.${o
                        .toLowerCase()
                        .replace(/\W+/g, "_")}`;
                      const errorDataNested = getNestedValue(
                        errorData,
                        `${index}.${o.toLowerCase().replace(/\W+/g, "_")}`
                      );
                      return (
                        <div
                          key={o}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {data.types[data.options.indexOf(o)] ===
                          "textarea" ? (
                            <textarea
                              className={`input-box ${
                                errorDataNested ? "error-input" : ""
                              }`}
                              style={{ height: "80px" }}
                              {...register(registerNameCopy, {
                                required:
                                  index + 1 > data.min ? true : data.required,
                              })}
                              aria-invalid={errorDataNested ? "true" : "false"}
                            />
                          ) : (
                            <input
                              type={data.types[data.options.indexOf(o)]}
                              {...register(registerNameCopy, {
                                required:
                                  index + 1 > data.min ? true : data.required,
                              })}
                              className={`input-box ${
                                errorDataNested && "error-input"
                              }`}
                              aria-invalid={errorDataNested ? "true" : "false"}
                            />
                          )}
                          <label style={{ marginLeft: "12px" }}>{o}</label>
                        </div>
                      );
                    })}
                    {errorData && errorData[index] && (
                      <span
                        style={{ display: "block", gridColumn: "1 / -1" }}
                        className="error-text"
                      >
                        {index + 1 > data.min
                          ? "Please complete the required field OR remove the row."
                          : "Please complete the required field."}
                      </span>
                    )}
                    {/* Remove Button */}
                    {index + 1 > data.min && (
                      <button
                        type="button"
                        className="button-box-array"
                        // positioned in next row right under right-most box
                        style={{ gridColumn: questionNum, gridRow: 1 / 5 + 1 }}
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Remove
                      </button>
                    )}
                    {index + 1 === fields.length && (
                      <button
                        type="button"
                        className="button-box-array"
                        style={{
                          justifySelf: "start",
                          gridColumn: 1,
                          gridRow: 1 / 5 + 2,
                        }}
                        onClick={() => {
                          append(structure);
                        }}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
                {/* error message */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
